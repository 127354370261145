/**
 * @description Authentication status flags
 */
enum AuthenticationStatus {

    /**
     * @description The user is authenticated
     */
    AUTHENTICATION_SUCCESSFUL = 1,

    /**
     * @description Authentication is in progress
     */
    AUTHENTICATION_IN_PROGRESS = 2,

    /**
     * @description The user is not authenticated
     */
    UNAUTHENTICATED = 0,

    /**
     * @description An error occurred during authentication
     */
    AUTHENTICATION_ERROR = -1
}

export default AuthenticationStatus;