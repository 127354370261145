import { FloatingLabel, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { v4 as uuid } from "uuid";
import ITextFieldProps from "./IFloatingTextFieldProps";
import { IsValid, ValidationCssClass } from "../Validation";
import styles from "../formFields.module.scss";

export default function FloatingTextField(props: ITextFieldProps) {
    const { t } = useTranslation();

    const validationMessage = props.validationMessage || t("general.provideValue");
    
    let validationCssClass = ValidationCssClass(props.validation);
    let isValid = IsValid(props.validation);

    return (
        <FloatingLabel  className="mb-3"
                        controlId={`txt-${uuid()}`}
                        label={props.label}>
            <Form.Control   type={props.type}
                            placeholder={props.placeholder}
                            className={`${validationCssClass} ${styles.formControl}`}
                            value={props.value}
                            isValid={isValid}
                            disabled={props.disabled}
                            onChange={(e) => props.onChange(e.target.value)}
                            />
            {
                isValid === false &&
                <div className="text-danger">
                    {validationMessage}
                </div>
            }
        </FloatingLabel>
    );
}