import ICategory from "../models/helpdesk/ICategory";
import ISubcategory from "../models/helpdesk/ISubcategory";
import ITicket from "../models/helpdesk/hr/ITicket";
import networkService from "./networkService";

class HelpdeskService {

    /**
     * @description Gets the HR support categories
     */
    async getCategories(): Promise<ICategory[]> {
        const result = await networkService.get<ICategory[]>("helpdesk/hr/categories");

        if (result.isSuccessful) {
            return result.data as ICategory[];
        }

        throw result.data;
    }

    /**
     * @description Gets the subcategories for a support category
     * @param {string} categoryId The ID of the category to get the subcategories for
     */
    async getSubcategories(categoryId: string): Promise<ISubcategory[]> {
        const result = await networkService.get<ISubcategory[]>(`helpdesk/hr/category/${categoryId}/subcategories`);

        if (result.isSuccessful) {
            return result.data as ISubcategory[];
        }

        throw result.data;
    }

    /**
     * @description Fetches the tickets from the IT helpdesk
     */
    async getTickets() : Promise<any> {
        const result = await networkService.get<any>(`helpdesk/hr/tickets`);

        if (result.isSuccessful) {
            return result.data;
        }

        throw result.data;
    }

    /**
     * @description Fetches a ticket from the HR helpdesk
     * @param {string} ticketId The ID of the ticket to fetch
     */
    async getTicket(ticketId: number) : Promise<ITicket> {
        const result = await networkService.get<ITicket>(`helpdesk/hr/ticket/${ticketId}`);

        if (result.isSuccessful) {
            return result.data as ITicket;
        }

        throw result.data;
    }

    /**
     * @description Creates a ticket in the HR helpdesk
     * @param {ITicket} ticket The ticket to create
     * @returns {number} The ID of the created ticket
     */
    async createTicket(ticket: ITicket) : Promise<number> {
        const result = await networkService.put<ITicket, number>(`helpdesk/hr/ticket`, ticket);

        if (result.isSuccessful) {
            return result.data as number;
        }

        throw result.data;
    }

}

const hrHelpdeskService = new HelpdeskService();
export default hrHelpdeskService;