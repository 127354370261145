import { useTranslation } from "react-i18next";
import ISendMessageButtonProps from "./ISendMessageButtonProps";
import { useEffect, useState } from "react";
import Status from "../../../enums/Status";
import accountService from "../../../services/accountService";
import { Button, Dropdown, SplitButton } from "react-bootstrap";
import { MessageAction } from "../../../enums/MessageAction";
import { Icon } from "../../../components/icon/Icon";

const showAwaitUserResponseOptionStatusIdList = [
    process.env.REACT_APP_WEBCON_IT_HD_WORKING_ON_TICKET_STEP_ID,
    process.env.REACT_APP_WEBCON_IT_HD_WAITING_FOR_MACRO_STEP_ID
];

const showCompleteTicketOptionStatusIdList = [
    process.env.REACT_APP_WEBCON_IT_HD_WORKING_ON_TICKET_STEP_ID,
    process.env.REACT_APP_WEBCON_IT_HD_PENDING_FEEDDBACK_STEP_ID
];

export default function SendMessageButton(props: ISendMessageButtonProps) {
    const { t } = useTranslation();

    const [status, setStatus] = useState<Status>(Status.LOADING);
    const [showMessageActions, setShowMessageActions] = useState<boolean>(false);

    useEffect(() => {
        accountService.getGroupMemberships()
            .then(groups => {
                const shouldShowActions = 
                    groups.some(g => g.id === process.env.REACT_APP_IT_SUPPORT_GROUP_ID) &&
                    (
                        showAwaitUserResponseOptionStatusIdList.includes(props.ticketStatusId) ||
                        showCompleteTicketOptionStatusIdList.includes(props.ticketStatusId)
                    );

                setShowMessageActions(shouldShowActions);
            })
            .catch(() => setShowMessageActions(false))
            .finally(() => setStatus(Status.LOAD_SUCCESS));
    }, []);

    const onClick = (action: MessageAction) => props.onClick(action);

    if (status === Status.LOADING) {
        return (
            <Button variant="primary" size="lg" disabled>
                {t("comms.form.action.SEND_ONLY")}
            </Button>
        );
    }

    if (!showMessageActions) {
        return (
            <Button variant="primary" size="lg" onClick={() => onClick(MessageAction.SEND_ONLY)}>
                {t("comms.form.action.SEND_ONLY")}
            </Button>
        );
    }

    return (
        <div className="d-grid">
            <SplitButton
                variant="primary"
                drop="up-centered"
                size="lg"
                title={t("comms.form.action.SEND_ONLY")}
                onClick={() => onClick(MessageAction.SEND_ONLY)}
                >
                    <Dropdown.ItemText>
                        <div className="d-flex align-items-center">
                            <span className="d-inline-block text-warning me-2">
                                <Icon iconName="ExclamationTriangleFill" />
                            </span>
                            <span className="small text-muted">
                                {t("comms.form.action.warning")}
                            </span>
                        </div>
                    </Dropdown.ItemText>
                {
                    showAwaitUserResponseOptionStatusIdList.includes(props.ticketStatusId) &&
                    <Dropdown.Item onClick={() => onClick(MessageAction.SEND_AND_AWAIT_FEEDBACK)}>
                        {t("comms.form.action.SEND_AND_AWAIT_FEEDBACK")}
                    </Dropdown.Item>
                }

                {
                    showCompleteTicketOptionStatusIdList.includes(props.ticketStatusId) &&
                    <Dropdown.Item onClick={() => onClick(MessageAction.SEND_AND_CLOSE)}>
                        {t("comms.form.action.SEND_AND_CLOSE")}
                    </Dropdown.Item>
                }
            </SplitButton>
        </div>
    );
}