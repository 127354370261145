import axios from "axios";
import msalInstance from "../msalConfig";
import IGroup from "../models/groups/IGroup";

class MSGraphClient {

    private readonly baseUrl = 'https://graph.microsoft.com/v1.0/';
    private accessToken: string;

    constructor(accessToken: string) {
        this.accessToken = accessToken;
    }

    public async me() {
        const response = await axios.get(`${this.baseUrl}me`, {
            headers: {
                Authorization: `Bearer ${this.accessToken}`,
            }
        });

        return response.data;
    }

    public async myPhoto() {
        const response = await axios.get(`${this.baseUrl}me/photo/$value`, {
            headers: {
                Authorization: `Bearer ${this.accessToken}`,
            },
            responseType: 'blob'
        });

        const blob = response.data;
        const base64 = await this.blobToBase64(blob);

        return base64;
    }

    public async myMemberships(membershipType?: string) {
        let url = `${this.baseUrl}me/memberOf`;
        if (membershipType) {
            url += `/${membershipType}`;
        }

        const response = await axios.get(url, {
            headers: {
                Authorization: `Bearer ${this.accessToken}`,
            }
        });

        return response.data.value;
    }

    public async photo(upn: string) {
        const response = await axios.get(`${this.baseUrl}users/${upn}/photo/$value`, {
            headers: {
                Authorization: `Bearer ${this.accessToken}`,
            },
            responseType: 'blob'
        });

        const blob = response.data;
        const base64 = await this.blobToBase64(blob);

        return base64;
    }

    private async blobToBase64(blob: Blob) {
        const arrayBuffer = await blob.arrayBuffer();
        const uint8Array = new Uint8Array(arrayBuffer);
        const bytes = uint8Array.reduce((data, byte) => data + String.fromCharCode(byte), '');
        const base64 = btoa(bytes);

        return base64;
    }
}

const CreateGraphClient = (accessToken: string) => new MSGraphClient(accessToken);
export default CreateGraphClient;

/**
 * @description Get the access token from the active account
 * @returns The user's access token
 */
export async function getAccessToken() {
    const account = msalInstance.getActiveAccount()!;
    
    const request = {
        scopes: ["User.Read"],
        account: account,
    };

    try {
        const response = await msalInstance.acquireTokenSilent(request);
        return response.accessToken;
    } catch (error) {
        console.error(error);
    }
}