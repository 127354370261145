import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Requests from "./requests/Requests";

export function Overview() {
    const { t } = useTranslation();

    return (
        <Container fluid>
            <Row>
                <Col>
                    <h1>{t("approval.titles.plural")}</h1>
                    <p className="lead">{t("approval.description")}</p>
                    <Requests />
                </Col>
            </Row>
        </Container>
    );
}