import { Children, ForwardedRef, forwardRef } from "react";
import IDropdownSelectProps from "./IDropdownSelectProps";
import { Icon } from "../../icon/Icon";
import { Button, Dropdown } from "react-bootstrap";
import { v4 as uuid } from "uuid";
import styles from './DropdownSelect.module.scss';
import { useTranslation } from "react-i18next";
import { mergeCss } from "@fluentui/merge-styles";
import { IsValid, ValidationCssClass } from "../Validation";

const toggle = forwardRef(({ onClick, label, disabled, value, imgValue, validation }: any, ref: ForwardedRef<HTMLButtonElement>) => {
    const { t } = useTranslation();

    const validationCssClass = ValidationCssClass(validation);
    let btnStyles = mergeCss([ "text-start", "text-black", styles.button, validationCssClass ]);
    
    return (
        <div className="d-grid">
            <Button ref={ref}
                    type="button"
                    size="lg"
                    variant="outline-primary"
                    className={btnStyles}
                    disabled={disabled}
                    onClick={onClick}>
                <div className="d-flex align-items-center">
                    {
                        value && imgValue &&
                        <img    className="me-2"
                                src={imgValue}
                                alt={label}
                                height="24px"
                                width="24px" />
                    }
                    <div className="d-block">
                        <span className={styles.label}>{label}</span>
                        <span className={styles.value}>{value || t("general.pleaseSelect")}</span>
                    </div>
                    <div className={`d-block ms-auto ${styles.icon}`}>
                        <Icon iconName="ChevronDown" />
                    </div>
                </div>
            </Button>
        </div>
    );
});

const menu = forwardRef(({ children, className } : any, ref: ForwardedRef<HTMLDivElement>) => {
    return (
        <div ref={ref} className={`${className} w-100`}>
            <ul className="list-unstyled">
                {Children.toArray(children)}
            </ul>
        </div>
    );
});

export default function DropdownSelect<TItem>(props: IDropdownSelectProps<TItem>) {
    const { t } = useTranslation();

    const handleChange = (item: TItem) => {
        if (item) {
            props.onchange(item);
        }
    };

    const hasImage =    props.value && props.itemLogoField &&
                        !!(props.items.filter((i: any) => i[props.itemValueField] === props.value)[0] as any)[props.itemLogoField!];

    const imgValue =    hasImage
                            ? (props.items.filter((i: any) => i[props.itemValueField] === props.value)[0] as any)[props.itemLogoField!]
                            : undefined;

    const blankImageCss = mergeCss([ "d-block", "me-2", { width: "24px" } ]);

    const validationMessage = props.validationMessage || t("general.provideValue");
    const isValid = IsValid(props.validation);
    
    return (
        <div className="d-block mb-3">
            <div className="d-grid">
                <Dropdown>
                    <Dropdown.Toggle    as={toggle}
                                        label={props.title}
                                        value={props.valueLabel}
                                        imgValue={imgValue}
                                        disabled={props.disabled}
                                        validation={props.validation}
                                        />
                    <Dropdown.Menu as={menu}>
                        {
                            props.items.map(
                                (item: any) => {
                                    const isActive = item[props.itemValueField] === props.value;

                                    return (
                                        <Dropdown.Item key={uuid()} onClick={_ => handleChange(item)} active={isActive} as="li">
                                            <div className="d-flex align-items-center text-start">
                                                {
                                                    (
                                                        props.itemLogoField && item[props.itemLogoField] &&
                                                        <img    className="me-2"
                                                                src={item[props.itemLogoField]}
                                                                alt={item[props.itemLabelField]}
                                                                height="24px"
                                                                width="24px" />
                                                    ) ||
                                                    (
                                                        props.items.some((i: any) => i[props.itemLogoField!]) &&
                                                        <div className={blankImageCss} />
                                                    )
                                                }
                                                <span className="d-inline-block py-1">
                                                    {item[props.itemLabelField]}
                                                </span>
                                            </div>
                                        </Dropdown.Item>
                                    );
                                }
                            )
                        }
                    </Dropdown.Menu>
                </Dropdown>
            </div>
            {
                isValid === false &&
                <div className="text-danger">
                    {validationMessage}
                </div>
            }
        </div>
    );
}