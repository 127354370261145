import { BrowserRouter, useLocation } from 'react-router-dom';
import { ProtectedRoutes } from './Routes';
import { useMsal } from '@azure/msal-react';
import Authorise from '../authentication/authorise/Authorise';
import { useEffect, useState } from 'react';
import Navbar from '../components/navbar/Navbar';
import QuickComms from '../comms/quick-comms/QuickComms';

const AuthenticatedApp = () => {
    const location = useLocation();

    if (location.pathname === '/quick-comms') {
        const queryParams = new URLSearchParams(location.search);
        const ticketId = Number(queryParams.get('ticketId'));

        return <QuickComms ticketId={ticketId} />;
    }

    return (
        <div>
            <Navbar />
            <main role="main">
                <ProtectedRoutes />
            </main>
        </div>
    );
};

export default function App() {
    const { accounts } = useMsal();

    const [isAuthenticated, setIsAuthenticated] = useState<boolean | undefined>(undefined);
    useEffect(() => setIsAuthenticated(accounts.length > 0), [accounts]);

    return (
        <BrowserRouter>
            {
                {
                    "true": <AuthenticatedApp />,
                    "false": <Authorise />,
                    "undefined": null
                }[String(isAuthenticated)]
            }
        </BrowserRouter>
    );
}