import { useEffect, useState } from "react";
import Dropzone from "../../../../../components/formFields/dropzone/Dropzone";
import IDropzoneItem from "../../../../../models/dropzone/IDropzoneItem";
import Status from "../../../../../enums/Status";
import { v4 as uuid } from "uuid";
import { Alert, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import IAttachment from "../../../../../models/attachments/IAttachment";
import attachmentService from "../../../../../services/attachmentService";
import IUploadAreaProps from "./IUploadAreaProps";

export default function UploadArea(props: IUploadAreaProps) {
    const { t } = useTranslation();
    
    const [uploadedFiles, setUploadedFiles] = useState<number>(0);
    const [dropzoneFiles, setDropzoneFiles] = useState<IDropzoneItem[]>([]);
    const [status, setStatus] = useState<Status>(Status.NEUTRAL);
    const [isReadOnly, setIsReadOnly] = useState<boolean>(true);

    useEffect(() => setIsReadOnly([Status.SAVING, Status.LOADING, Status.LOAD_ERROR].includes(status)), [status]);

    const handleFileDrop = (acceptedFiles: File[]) => {
        const newFiles = acceptedFiles.map(file => ({ id: uuid(), file }));
        setDropzoneFiles(files => [...files, ...newFiles]);
        setStatus(Status.NEUTRAL);
    };

    const handleFileRemove = (id: string) => {
        setDropzoneFiles(files => files.filter(f => f.id !== id));
        setStatus(Status.NEUTRAL);
    };

    const handleUpload = () => {
        setStatus(Status.SAVING);
        uploadFiles(props.ticketId, dropzoneFiles)
            .then(() => {
                setStatus(Status.SAVE_SUCCESS);
                setUploadedFiles(uploadedFiles + dropzoneFiles.length);
                setDropzoneFiles([]);
                setTimeout(() => {
                    setStatus(Status.NEUTRAL);
                    props.onUpload();
                }, 2_000);
            })
            .catch(() => setStatus(Status.SAVE_ERROR));
    };
    
    return (
        <div className="d-grid gap-2">
            <Dropzone
                items={dropzoneFiles}
                onDrop={handleFileDrop}
                onRemove={handleFileRemove}
                />
            {
                status === Status.SAVE_SUCCESS &&
                <Alert variant="success" className="mb-0">
                    {
                        uploadedFiles > 1
                            ? t("attachments.success.multiple")
                            : t("attachments.success.single")
                    }
                </Alert>
            }
            {
                status === Status.SAVE_ERROR &&
                <Alert variant="danger" className="mb-0">
                    {
                        dropzoneFiles.length > 1
                            ? t("attachments.errors.generalUpload.multiple")
                            : t("attachments.errors.generalUpload.single")
                    }
                </Alert>
            }
            {
                dropzoneFiles.length > 0 &&
                <Button variant="primary" size="lg" disabled={isReadOnly} onClick={handleUpload}>
                    {status === Status.SAVING ? t("general.uploading") : t("general.upload")}
                </Button>
            }
        </div>
    );
}

async function uploadFiles(ticketId: number, items: IDropzoneItem[], ) {
    try {
        const attachments = await createAttachments(items, ticketId);
        for (const attachment of attachments) {
            await attachmentService.uploadAttachment(ticketId, attachment);
        }
    } catch (error) {
        throw error;
    }
}

const readFileAsBase64 = (file: File) : Promise<string> => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = reject;
        reader.readAsDataURL(file);
    });
};

const createAttachments = async (items: { file: File }[], ticketId: number) : Promise<IAttachment[]> => {
    const promises = items.map(async item => {
        let base64Content = await readFileAsBase64(item.file);
        base64Content = base64Content.split(',')[1];

        return {
            id: 0,
            name: item.file.name,
            size: item.file.size,
            type: item.file.type,
            content: base64Content,
            dateCreated: new Date(),
            ticketId
        };
    });

    return Promise.all(promises);
};