import { useDropzone } from "react-dropzone";
import styles from "./Dropzone.module.scss";
import { useTranslation } from "react-i18next";
import IDropzoneProps from "./IDropzoneProps";
import { mergeStyles } from "@fluentui/merge-styles";
import PreviewItem from "./previewItem/PreviewItem";

const Dropzone = (props: IDropzoneProps) => {
    const { t } = useTranslation();

    const { getRootProps, getInputProps } = useDropzone({
        onDrop: files => props.onDrop(files),
        disabled: props.disabled
    });

    const className = !props.disabled
        ? mergeStyles(styles.dropzone, "mb-3")
        : mergeStyles(styles.dropzone, styles.disabled, "mb-3");

    return (
        <div>
            <div {...getRootProps({ className })}>
                <input {...getInputProps()} />
                <p className="d-inline d-md-none">{t("dropzone.placeholder.small")}</p>
                <p className="d-none d-md-inline">{t("dropzone.placeholder.large")}</p>
            </div>
            {props.items.length > 0 && (
                <ul className={styles.previews}>
                    {
                        props.items.map((item) => {
                            const id = item.id;
                            const onRemove = () => props.onRemove(id);
                            
                            return <PreviewItem key={item.id} file={item.file} onRemove={onRemove} />;
                        })
                    }
                </ul>
            )}
        </div>
    );
}

export default Dropzone;