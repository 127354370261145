import { Alert, Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import FloatingTextField from "../../components/formFields/floatingText/FloatingTextField";
import { useEffect, useState } from "react";
import IMessage from "../../models/helpdesk/comms/IMessage";
import Status from "../../enums/Status";
import FormFieldValidation from "../../enums/FormFieldValidation";
import RichTextField from "../../components/formFields/richText/RichTextField";
import communicationService from "../../services/communicationsService";
import INewMessageProps from "./INewMessageProps";
import { MessageAction } from "../../enums/MessageAction";
import newMessage from "./NewMessageObject";
import IMessageValidation from "./IMessageValidation";
import SendMessageButton from "./sendMessageButton/SendMessageButton";

export default function NewMessage(props: INewMessageProps) : JSX.Element {
    const { t } = useTranslation();

    const submitMessage = (action: MessageAction) => {
        const isValid = validate(message, setValidation);
        if (!isValid) {
            return;
        }

        sendMessage(message, action, setStatus)
            .then((result: boolean) => {
                if (!result) {
                    return;
                }

                props.onMessageSent();
                
                if (action === MessageAction.SEND_ONLY) {
                    setMessage(newMessage(props.ticketId));
                } else {
                    window.location.reload();
                }
            });
    };

    const [status, setStatus] = useState<Status>(Status.NEUTRAL);
    const [isReadOnly, setIsReadOnly] = useState<boolean>(false);
    
    const [message, setMessage] = useState<IMessage>(newMessage(props.ticketId));

    const [validation, setValidation] = useState<IMessageValidation>({
        subject: FormFieldValidation.NOT_VALIDATED,
        body: FormFieldValidation.NOT_VALIDATED
    });

    useEffect(() => setIsReadOnly([Status.SAVE_SUCCESS, Status.SAVING, Status.LOADING, Status.LOAD_ERROR].includes(status)), [status]);

    const hasValidationErrors = Object.values(validation).some(v => v === FormFieldValidation.INVALID);

    const handleInputChange = (prop: keyof IMessage, value: any) => {
        setMessage(message => ({ ...message, [prop]: value }));
        setValidation(validation => ({ ...validation, [prop]: FormFieldValidation.NOT_VALIDATED }));
    };

    return (
        <Form onSubmit={(e) => e.preventDefault()}>
            <h4>{t("comms.new")}</h4>
            <Row>
                <Col>
                    <FloatingTextField
                        label={t("comms.form.fields.title.label")}
                        type="text"
                        placeholder={t("comms.form.fields.title.placeholder")}
                        value={message.subject}
                        disabled={isReadOnly}
                        onChange={value => handleInputChange("subject", value)}
                        validation={validation.subject}
                        validationMessage={t("comms.form.fields.title.validation")}
                        />
                </Col>
            </Row>
            <Row>
                <Col>
                    <RichTextField
                        value={message.body}
                        placeholder={t("comms.form.fields.content.label")}
                        disabled={isReadOnly}
                        onChange={value => handleInputChange("body", value)}
                        validation={validation.body}
                        validationMessage={t("comms.form.fields.content.validation")}
                        />
                </Col>
            </Row>

            <Row>
                <Col>
                    {
                        hasValidationErrors &&
                        <Alert variant="danger">{t("comms.errors.newMessageValidation")}</Alert>
                    }
                    {
                        status === Status.SAVING &&
                        <Alert variant="info">{t("comms.form.sending")}</Alert>
                    }
                    {
                        status === Status.SAVE_SUCCESS &&
                        <Alert variant="success">{t("comms.form.sent")}</Alert>
                    }
                    {
                        status === Status.SAVE_ERROR &&
                        <Alert variant="danger">{t("comms.errors.sendingMessage")}</Alert>
                    }
                </Col>
            </Row>
            
            <Row className="justify-content-center">
                <Col xs={12} md={10} lg={6} xl={4}>
                    <SendMessageButton onClick={submitMessage} ticketStatusId={props.ticketStatusId} />
                </Col>
            </Row>
        </Form>
    );
}

async function sendMessage (
    message: IMessage,
    action: MessageAction,
    setStatus: (status: Status) => void
): Promise<boolean> {
    setStatus(Status.SAVING);

    try {
        const result = await communicationService.createMessage(message, action);
        if (!result) {
            setStatus(Status.SAVE_ERROR);
            return false;
        }

        setStatus(Status.SAVE_SUCCESS);
        return true;
    } catch (_) {
        setStatus(Status.SAVE_ERROR);
        return false;
    }
}

function validate(
    message: IMessage,
    setValidation: (validation: IMessageValidation) => void
) {
    const validation: IMessageValidation = {
        subject: message.subject.length > 0 
            ? FormFieldValidation.VALID
            : FormFieldValidation.INVALID,
        body: message.body.length > 0
            ? FormFieldValidation.VALID
            : FormFieldValidation.INVALID
    };

    setValidation(validation);

    return Object.values(validation).every(v => v === FormFieldValidation.VALID);
}