import { Col, Form, Row } from "react-bootstrap";
import Status from "../../enums/Status";
import IOnboarding from "../../models/hr/onboarding/IOnboarding";
import IOnboardingValidation from "./IOnboardingValidation";
import { v4 as uuid } from "uuid";
import { useTranslation } from "react-i18next";
import DropdownSelect from "../../components/formFields/dropdownSelect/DropdownSelect";
import FormFieldValidation from "../../enums/FormFieldValidation";
import FloatingTextField from "../../components/formFields/floatingText/FloatingTextField";
import LargeFloatingTextField from "../../components/formFields/largeFloatingText/LargeFloatingTextField";
import ILaptopType from "../../models/hr/onboarding/ILaptopType";

interface IOnboardingFormBodyProps {
    handleInputChange: (prop: keyof IOnboarding, value: any) => void;
    handleSubmit: () => void;
    isReadOnly: boolean;
    status: Status;
    onboarding: IOnboarding;
    laptops: ILaptopType[];
    validation: IOnboardingValidation;
}

export default function OnboardingFormBody(props: IOnboardingFormBodyProps) {
    const  { t } = useTranslation();
    
    const chkLaptopId = `chk-asset-laptop-${uuid()}`;
    const chkPhoneId = `chk-asset-phone-${uuid()}`;
    const chkPrismId = `chk-access-prism-${uuid()}`;
    const chkConceptId = `chk-access-concept-${uuid()}`;

    const laptopName = props.laptops.find(laptop => laptop.typeId === props.onboarding.selectedLaptopTypeId)?.name;

    const handleInputChange = (prop: keyof IOnboarding, value: any) => {
        console.log(prop, value);
        props.handleInputChange(prop, value);
        // TODO: Validation
    }

    return (
        <Row>
            <Col>
                <Row>
                    <Col>
                        <h2 className="h5">
                            {t("onboarding.form.headers.assets")}
                        </h2>

                        <Row>
                            <Col xs={12} lg={6} xl={4}>
                                <div key={chkLaptopId} className="mb-2">
                                    <Form.Check type="checkbox"
                                                id={chkLaptopId}
                                                label={t("onboarding.form.fields.laptop.label")}
                                                checked={props.onboarding.isLaptopRequired}
                                                onChange={(event) => handleInputChange("isLaptopRequired", event.target.checked)}
                                                disabled={props.isReadOnly}
                                                />
                                </div>

                                {
                                    props.onboarding.isLaptopRequired &&
                                    <DropdownSelect 
                                        title={t("onboarding.form.fields.laptopType.label")}
                                        items={props.laptops.sort((a, b) => a.orderBy - b.orderBy)}
                                        value={props.onboarding.selectedLaptopTypeId!}
                                        itemValueField="typeId"
                                        itemLabelField="name"
                                        valueLabel={laptopName!}
                                        validation={props.validation.selectedLaptopTypeId}
                                        validationMessage={t("onboarding.form.fields.laptopType.validation")}
                                        disabled={props.isReadOnly}
                                        onchange={(item: any) => handleInputChange("selectedLaptopTypeId", item.typeId)} />
                                }

                                <div key={chkPhoneId} className="mb-3">
                                    <Form.Check type="checkbox"
                                                id={chkPhoneId}
                                                label={t("onboarding.form.fields.phone.label")}
                                                checked={props.onboarding.isPhoneRequired}
                                                onChange={(event) => handleInputChange("isPhoneRequired", event.target.checked)}
                                                disabled={props.isReadOnly}
                                                />
                                </div>
                            </Col>
                            {
                                (props.onboarding.isLaptopRequired || props.onboarding.isPhoneRequired) &&
                                <Col xs={12} lg={6} xl={8}>
                                    <h2 className="h5">
                                        {t("onboarding.form.headers.deliveries")}
                                    </h2>

                                    <LargeFloatingTextField
                                        label={t("onboarding.form.fields.deliveryAddress.label")}
                                        placeholder={t("onboarding.form.fields.deliveryAddress.label")}
                                        disabled={props.isReadOnly}
                                        onChange={(value) => props.handleInputChange("deliveryAddress", value)}
                                        validation={props.validation.deliveryAddress}
                                        type="textarea"
                                        value={props.onboarding.deliveryAddress!}
                                        height={150}
                                        />
                                </Col>
                            }
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h2 className="h5">
                            {t("onboarding.form.headers.financials")}
                        </h2>

                        <FloatingTextField
                            label={t("onboarding.form.fields.costCode.label")}
                            type="text"
                            placeholder={t("onboarding.form.fields.costCode.placeholder")}
                            value={props.onboarding.costCode}
                            disabled={props.isReadOnly}
                            onChange={(value) => handleInputChange("costCode", value)}
                            validation={props.validation.costCode}
                            validationMessage={t("onboarding.form.fields.costCode.validation")}
                            />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h2 className="h5">
                            {t("onboarding.form.headers.access")}
                        </h2>
                        
                        <LargeFloatingTextField
                            label={t("onboarding.form.fields.access.sp.label")}
                            placeholder={t("onboarding.form.fields.access.sp.label")}
                            disabled={props.isReadOnly}
                            onChange={(value) => props.handleInputChange("sharePointAccessRequired", value)}
                            type="textarea"
                            validation={FormFieldValidation.NOT_VALIDATED}
                            value={props.onboarding.sharePointAccessRequired!}
                            height={150}
                            />
                        <LargeFloatingTextField
                            label={t("onboarding.form.fields.access.pbi.label")}
                            placeholder={t("onboarding.form.fields.access.pbi.label")}
                            disabled={props.isReadOnly}
                            onChange={value => handleInputChange("powerBIAccessRequired", value)}
                            type="textarea"
                            value={props.onboarding.powerBIAccessRequired!}
                            height={150}
                            validation={FormFieldValidation.NOT_VALIDATED}
                            />

                        <div key={chkPrismId} className="mb-3">
                            <Form.Check type="checkbox"
                                id={chkPrismId}
                                label={t("onboarding.form.fields.access.prism.label")}
                                checked={props.onboarding.isPrismAccessRequired}
                                onChange={(event) => props.handleInputChange("isPrismAccessRequired", event.target.checked)}
                                disabled={props.isReadOnly}
                                />
                            <span className="small text-muted fw-bold">
                                {t("onboarding.form.fields.access.prism.subtitle")}
                            </span>
                        </div>
                        
                        {
                            props.onboarding.isPrismAccessRequired &&
                            <LargeFloatingTextField
                                label={t("onboarding.form.fields.access.prism.label")}
                                placeholder={t("onboarding.form.fields.access.prism.label")}
                                disabled={props.isReadOnly}
                                onChange={(value) => props.handleInputChange("prismAccessRequired", value)}
                                validation={props.validation.prismAccessRequired}
                                type="textarea"
                                value={props.onboarding.prismAccessRequired!}
                                height={150}
                                />
                        }
                            
                        <div key={chkConceptId} className="mb-3">
                            <Form.Check type="checkbox"
                                        id={chkConceptId}
                                        label={t("onboarding.form.fields.access.concept.label")}
                                        checked={props.onboarding.isConceptAccessRequired}
                                        onChange={(event) => props.handleInputChange("isConceptAccessRequired", event.target.checked)}
                                        disabled={props.isReadOnly}
                                        />
                        </div>

                        {
                            props.onboarding.isConceptAccessRequired &&
                            <LargeFloatingTextField
                                label={t("onboarding.form.fields.access.concept.label")}
                                placeholder={t("onboarding.form.fields.access.concept.label")}
                                disabled={props.isReadOnly}
                                onChange={(value) => props.handleInputChange("conceptAccessRequired", value)}
                                validation={props.validation.conceptAccessRequired}
                                type="textarea"
                                value={props.onboarding.conceptAccessRequired!}
                                height={150}
                                />
                        }
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}