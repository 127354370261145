import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEN from './en/translation.json';

export default function initialiseTranslations() {
    i18n
        .use(initReactI18next)
        .init({
            interpolation: { 
                escapeValue: false
            },
            lng: 'en',
            resources: {
                en: {
                    translation: translationEN
                }
            }
        });
}