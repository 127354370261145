import IAttachment from "../models/attachments/IAttachment";
import networkService from "./networkService";

class AttachmentService {

    /**
     * @description Get all attachments for a specific element
     * @param {number} elementId The ID of the element to get attachments for
     */
    async getAttachments(elementId: number) : Promise<IAttachment[]> {
        const result = await networkService.get<IAttachment[]>(`helpdesk/${elementId}/attachments`);

        if (result.isSuccessful) {
            return result.status === 200
                ? result.data as IAttachment[]
                : [];
        }

        throw result.data;
    }

    /**
     * @description Downloads an attachment
     * @param {number} attachmentId The ID of the attachment to download
     */
    async downloadAttachment(attachmentId: number) : Promise<Blob> {
        return await networkService.download(`helpdesk/attachment/${attachmentId}`);
    }

    /**
     * @description Uploads an attachment to a specific WEBCON element
     * @param {number} elementId The ID of the WEBCON element to upload the attachment to
     * @param {IAttachment} attachment The attachment to be uploaded
     */
    async uploadAttachment(elementId: number, attachment: IAttachment) : Promise<boolean> {
        const result = await networkService.put(`helpdesk/${elementId}/attachment`, attachment);
        return result.isSuccessful;
    }

}

const attachmentService = new AttachmentService();
export default attachmentService;