import ILaptopType from "../models/hr/onboarding/ILaptopType";
import IOnboarding from "../models/hr/onboarding/IOnboarding";
import networkService from "./networkService";

class OnboardingService {

    /**
     * @description Retrieves an onboarding instance by its ID.
     * @param id - The unique identifier of the onboarding instance.
     * @returns A promise that resolves to the onboarding instance.
     * @throws Will throw an error if the network request is unsuccessful.
     */
    async getInstance(id: number): Promise<IOnboarding> {
        const result = await networkService.get<IOnboarding>(`onboarding/${id}`);

        if (result.isSuccessful) {
            return result.data as IOnboarding;
        }

        throw result.data;
    }

    /**
     * @description Saves an onboarding instance to the database.
     * @param {IOnboarding} onboarding The onboarding instance to save.
     * @returns {boolean} A promise that resolves to true if the instance was saved successfully.
     */
    async saveInstance(onboarding: IOnboarding): Promise<boolean> {
        const result = await networkService.patch<IOnboarding, void>("onboarding", onboarding);
        return result.isSuccessful;
    }

    /**
     * @description Submits an onboarding instance to the database.
     * @param {IOnboarding} onboarding The onboarding instance to submit.
     * @returns {boolean} A promise that resolves to true if the instance was submitted successfully.
     * @throws Will throw an error if the network request is unsuccessful.
     */
    async submitInstance(onboarding: IOnboarding): Promise<boolean> {
        const result = await networkService.post<IOnboarding, void>("onboarding", onboarding);
        return result.isSuccessful
    }


    /**
     * @description Fetches the available laptop types from the onboarding service.
     * @returns {Promise<IStaticValue[]>} A promise that resolves to an array of static values representing laptop types.
     * @throws Will throw an error if the network request is unsuccessful.
     */
    async getLaptopTypes() : Promise<ILaptopType[]> {
        const result = await networkService.get<ILaptopType[]>("onboarding/laptop-types");

        if (result.isSuccessful) {
            return result.data as ILaptopType[];
        }

        throw result.data;
    }

}

const onboardingService = new OnboardingService();
export default onboardingService;