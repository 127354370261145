import { useEffect, useState } from "react";
import Status from "../../enums/Status";
import IArticle from "../../models/kb/IArticle";
import kbService from "../../services/kbService";
import { useTranslation } from "react-i18next";
import { v4 as uuid } from "uuid";
import { Alert, Col, Container, ListGroup, Row, Spinner } from "react-bootstrap";
import Article from "./Article";

const ArticlesHeader = () => {
    const { t } = useTranslation();

    return (
        <ListGroup.Item key={uuid()}>
            <Container fluid className="d-none d-lg-block">
                <Row>
                    <Col xs={4} className="fw-bold">{t("it.kb.articles.table.headers.title")}</Col>
                    <Col xs={2} className="fw-bold">{t("it.kb.articles.table.headers.category")}</Col>
                    <Col xs={2} className="fw-bold">{t("it.kb.articles.table.headers.system")}</Col>
                    <Col xs={2} className="fw-bold">{t("it.kb.articles.table.headers.author")}</Col>
                    <Col xs={2} className="fw-bold">{t("it.kb.articles.table.headers.published")}</Col>
                </Row>
            </Container>
        </ListGroup.Item>
    );
}

export default function Articles() {
    const { t } = useTranslation();

    const [status, setStatus] = useState<Status>(Status.LOADING);
    const [articles, setArticles] = useState<IArticle[]>([]);

    useEffect(() => {
        fetchArticles(setArticles)
            .then(() => setStatus(Status.LOAD_SUCCESS))
            .catch(() => setStatus(Status.LOAD_ERROR));
    }, []);
    
    return (
        <Container fluid>
            <Row className="mb-3">
                <Col>
                    {
                        (
                            status === Status.LOADING &&
                            <div className="text-center">
                                <Spinner />
                                <span className="d-block sfw-bold">{t("it.my.loading")}</span>
                            </div>
                        ) ||
                        (
                            status === Status.LOAD_ERROR &&
                            <Alert variant="danger">
                                {t("it.kb.articles.errors.general")}
                            </Alert>
                        ) ||
                        (
                            articles.length === 0 &&
                            <Alert variant="info">
                                {t("it.kb.articles.errors.noArticles")}
                            </Alert>
                        ) ||
                        <ListGroup variant="flush">
                            <ArticlesHeader />
                            {
                                articles.map(article => <Article key={uuid()} article={article} />)
                            }
                        </ListGroup>
                    }
                </Col>
            </Row>
        </Container>
    );
}

async function fetchArticles(setArticles: (articles: IArticle[]) => void) {
    const tickets = await kbService.getArticles();
    setArticles(tickets);
}