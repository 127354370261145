import { useParams } from "react-router-dom";
import Status from "../../../../../enums/Status";
import { useEffect, useState } from "react";
import itHelpdeskService from "../../../../../services/itHelpdeskService";
import IWork from "../../../../../models/helpdesk/it/IWork";
import { Alert, ListGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { v4 as uuid } from "uuid";
import dayjs from "dayjs";

export default function WorkTab() {
    const { ticketId } = useParams<{ ticketId: string }>();

    const { t } = useTranslation();
    
    const [status, setStatus] = useState<Status>(Status.LOADING);
    const [work, setWork] = useState<IWork[]>([]);

    useEffect(() => {
        fetchWork(parseInt(ticketId!))
            .then(work => {
                setWork(work);
                setStatus(Status.LOAD_SUCCESS);
            })
            .catch(() => setStatus(Status.LOAD_ERROR));
    }, [ticketId]);

    if (status === Status.LOADING) {
        return <Alert variant="info">{t("general.loading")}</Alert>;
    }

    if (status === Status.LOAD_ERROR) {
        return <Alert variant="danger">{t("it.work.errors.general")}</Alert>;
    }

    if (work.length === 0) {
        return <Alert variant="info">{t("it.work.errors.noWork")}</Alert>;
    }

    return (
        <>
            <ListGroup>
                {
                    work.map(w => {
                        const date = dayjs(w.start).format("DD/MM/YYYY");

                        let timeSpent = w.hoursSpent < 1
                            ? `${w.hoursSpent * 60} ${t("it.work.minutes")}`
                            : w.hoursSpent === 1
                                ? `${w.hoursSpent} ${t("it.work.hour")}`
                                : `${w.hoursSpent} ${t("it.work.hours")}`;

                        return (
                            <ListGroup.Item key={uuid()}>
                                <div dangerouslySetInnerHTML={{ __html: w.description }} />
                                <div className="d-flex justify-content-between small text-muted">
                                    <span>{w.technician}</span>
                                    <span>
                                        {date} - {timeSpent}
                                    </span>
                                </div>
                            </ListGroup.Item>
                        );
                    })
                }
            </ListGroup>
            <p className="small text-muted">
                {t("it.work.hiddenWork")}
            </p>
        </>
    );
}

async function fetchWork(ticketId: number) {
    const work = await itHelpdeskService.getWorkLogged(ticketId);
    return work;
}