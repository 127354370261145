import { Col, Row } from "react-bootstrap";
import ITicket from "../../../../../models/helpdesk/it/ITicket";
import { useTranslation } from "react-i18next";
import { System } from "./System";
import { Category } from "./Category";
import Questions from "./questions/Questions";

export default function DetailsTab({ ticket }: { ticket: ITicket }) {
    const { t } = useTranslation();
    
    return (
        <Row>
            <Col xs={12} className="mb-3 border-bottom">
                <div dangerouslySetInnerHTML={{ __html: ticket.description }} />
            </Col>
            {ticket.system && <System system={ticket.system} />}
            {ticket.category && <Category category={ticket.category} subcategory={ticket.subcategory} />}
            {
                ticket.platform && ticket.topic &&
                <ul>
                    <li dangerouslySetInnerHTML={{ __html: t("it.properties.platform", { platform: ticket.platform.name}) }} />
                    <li dangerouslySetInnerHTML={{ __html: t("it.properties.topic", { topic: ticket.topic.name}) }} />
                </ul>
            }
            {ticket.questions.length > 0 && <Questions questions={ticket.questions} />}
        </Row>
    );
}