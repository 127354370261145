import FormFieldValidation from "../../enums/FormFieldValidation";
import styles from "./formFields.module.scss";

const ValidationCssClass = (validation: FormFieldValidation): string =>
    ([FormFieldValidation.INVALID, FormFieldValidation.INVALID_EMAIL].includes(validation)) 
        ? styles.invalid
        : "";

const IsValid = (validation: FormFieldValidation): boolean => {
    switch (validation) {
        case FormFieldValidation.VALID:
            return true;
        case FormFieldValidation.INVALID:
        case FormFieldValidation.INVALID_EMAIL:
            return false;
        default:
            return null!;
    }
}

export { ValidationCssClass, IsValid };