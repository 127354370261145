import { useTranslation } from "react-i18next";
import ICategory from "../../../../../models/helpdesk/ICategory";
import ISubcategory from "../../../../../models/helpdesk/ISubcategory";
import { Col } from "react-bootstrap";

export const Category = ({ category, subcategory }: { category: ICategory, subcategory?: ISubcategory }) => {
    const { t } = useTranslation();

    return (
        <Col xs={12}>
            <span className="d-block fw-bold mb-1">{t("it.properties.category")}</span>
            <span className="d-block mb-3">
                {
                    (
                        category.url &&
                        <a href={category.url} target="_blank" rel="noreferrer" className="d-inline-block">{category.name}</a>
                    ) ||
                    <span className="d-inline">{category.name}</span>
                }
                {subcategory && <span className="d-inline">&nbsp;- {subcategory.name}</span>}
            </span>
        </Col>
    );
};