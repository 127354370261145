import { Alert, Card } from "react-bootstrap";
import BackButton from "../backButton/BackButton";
import IAlertBodyProps from "./IAlertBodyProps";

const AlertBody = (props: IAlertBodyProps) => (
    <Card.Body>
        <Card.Text as="div">
            <div className="d-flex align-items-center">
                {
                    props.backButtonPath && <BackButton path={props.backButtonPath} />
                }
                <Alert variant={props.variant} className="w-100 mb-0">
                    {props.message}
                </Alert>
            </div>
        </Card.Text>
    </Card.Body>
);

export default AlertBody;