import IMessage from "../../models/helpdesk/comms/IMessage";
import { NIL as NIL_UUID } from "uuid";

const newMessage = (ticketId: number): IMessage => ({
    id: NIL_UUID,
    ticketId: ticketId,
    sent: new Date(),
    subject: "",
    body: "",
    sender: {
        name: "",
        email: ""
    },
    recipients: []
});

export default newMessage;