import { useTranslation } from "react-i18next";
import IAsset from "../../models/assets/IAsset";
import ILAPSDetails from "../../models/assets/ILAPSDetails";
import { Alert, Button, Card, Col, Container, Row } from "react-bootstrap";
import BackButton from "../../components/backButton/BackButton";
import dayjs from "dayjs";
import { useState } from "react";
import { Icon } from "../../components/icon/Icon";

export default function LaptopDetails(props: { laptop: IAsset, laps: ILAPSDetails[] }) {
    const { laptop, laps } = props;
    const { t } = useTranslation();

    const [show, setShow] = useState<boolean>(false);

    const latestLaps = laps.sort((a, b) => getUnixTimestamp(b.lastRotation) - getUnixTimestamp(a.lastRotation))[0];
    
    const lastRotation = dayjs(latestLaps.lastRotation).format("DD/MM/YYYY HH:mm"),
        nextRotation = dayjs(latestLaps.nextRotation).format("DD/MM/YYYY HH:mm");

    const password = show
        ? atob(latestLaps.password)
        : "********";

    const iconName = show 
        ? "EyeSlash"
        : "Eye";

    return (
        <Card.Body>
            <Card.Title className="d-flex align-items-center mb-3">
                <BackButton path="/laps" />
                <span>{laptop.name}</span>
            </Card.Title>
            <Card.Text as="div">
                <Container fluid>
                    <Row>
                        <Col>
                            <h1 className="h2">{t("laps.title.short")}</h1>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h4>{t("laps.details.headers.deviceInfo")}</h4>
                            <ul>
                                <li>{t("laps.details.deviceName", { name: laptop.laptopName })}</li>
                                <li>{t("laps.details.serviceTag", { tag: laptop.serviceTag })}</li>
                            </ul>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h4>{t("laps.details.headers.password")}</h4>
                            <ul>
                                <li>{t("laps.details.lastReset", { date: lastRotation })}</li>
                                <li>{t("laps.details.nextReset", { date: nextRotation })}</li>
                                <li>{t("laps.details.adminUsername", { username: latestLaps.username })}</li>
                                <li>
                                    {t("laps.details.adminPassword", { password })}
                                    <Button type="button" variant="link" onClick={() => setShow(!show)}>
                                        <Icon iconName={iconName} />
                                    </Button>
                                </li>
                            </ul>
                            <Alert variant="info">
                                <div className="d-flex align-items-center">
                                    <Icon iconName="InfoCircleFill" />
                                    <div className="ms-2">
                                        <span dangerouslySetInnerHTML={{ __html: t("laps.details.usernameHint") }} />
                                    </div>
                                </div>
                            </Alert>
                        </Col>
                    </Row>
                </Container>
            </Card.Text>
        </Card.Body>
    )
}

const getUnixTimestamp = (date: Date | string) => dayjs(date).unix();