import { Badge, Card, Col, Container, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import BackButton from "../../components/backButton/BackButton";
import { Icon } from "../../components/icon/Icon";
import IArticle from "../../models/kb/IArticle";
import styles from "./ArticleBody.module.scss";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import userService from "../../services/userService";

export default function ArticleBody({ article }: { article: IArticle }) {
    const { t } = useTranslation();

    const [photo, setPhoto] = useState<string>(null!);

    useEffect(() => {
        userService.getPhoto(article.author.emailAddress)
            .then(photo => setPhoto(photo))
            .catch(() => setPhoto(null!));
    }, [article.author.emailAddress]);

    const published = dayjs(article.published).format("DD/MM/YYYY");

    return (
        <Card.Body>
            <Card.Title className="d-flex align-items-center mb-3">
                <BackButton path="/it/kb" />
                <span>{article.articleNumber}</span>
            </Card.Title>
            <Card.Text as="div">
                <Container fluid>
                    <Row>
                        <Col>
                            <h1 className="h2">{article.title}</h1>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col>
                            <div className="d-flex align-items-center fs-5 mb-1">
                                {
                                    (photo && <img src={`data:image/any;base64,${photo}`} alt={article.author.name} className={styles.avatar} />) ||
                                    <Icon iconName="Person" endMargin={2} />
                                }
                                <span className="">{article.author.name}</span>
                            </div>
                            <div className="d-flex align-items-center fs-6">
                                <Icon iconName="Calendar" endMargin={2} />
                                <span>{published}</span>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="d-flex gap-2 mb-3 w-100 overflow-x-auto">
                                <OverlayTrigger placement="top" overlay={<Tooltip>{t("ticket.badges.assignee")}</Tooltip>}>
                                    <Badge bg="info" className="fw-normal">
                                        <Icon iconName="InfoCircle" endMargin={1} />
                                        <span className="mb-0">{article.category.name}</span>
                                    </Badge>
                                </OverlayTrigger>
                                {
                                    article.system &&
                                    <OverlayTrigger placement="top" overlay={<Tooltip>{t("ticket.badges.assignee")}</Tooltip>}>
                                        <Badge bg="dark" className="fw-normal">
                                            <Icon iconName="Laptop" endMargin={1} />
                                            <span className="mb-0">{article.system!.name}</span>
                                        </Badge>
                                    </OverlayTrigger>
                                }
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div dangerouslySetInnerHTML={{ __html: article.content! }} />
                        </Col>
                    </Row>
                </Container>
            </Card.Text>
        </Card.Body>
    );
};