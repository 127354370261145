import ICategory from "../models/helpdesk/ICategory";
import ISubcategory from "../models/helpdesk/ISubcategory";
import IFeedback from "../models/helpdesk/it/IFeedback";
import IPlatform from "../models/helpdesk/it/IPlatform";
import ISystem from "../models/helpdesk/it/ISystem";
import ITicket from "../models/helpdesk/it/ITicket";
import ITopic from "../models/helpdesk/it/ITopic";
import IWork from "../models/helpdesk/it/IWork";
import networkService from "./networkService";

class HelpdeskService {

    /**
     * @description Gets the IT helpdesk platform types
     */
    async getPlatforms(): Promise<IPlatform[]> {
        const result = await networkService.get<IPlatform[]>("helpdesk/it/platforms");

        if (result.isSuccessful) {
            return result.status === 200
                ? result.data as IPlatform[]
                : [];
        }

        throw result.data;
    }

    /**
     * @description Gets the topics for the specified IT helpdesk platform
     * @param {string} platformId The ID of the platform to get the topics for
     * @param {string} topicId The ID of the topic to get the child topics for
     */
    async getTopics(platformId: string, topicId?: string): Promise<ITopic[]> {
        const result = await networkService.get<ITopic[]>(`helpdesk/it/platform/${platformId}/topics${topicId ? `?topicId=${topicId}` : ""}`);

        if (result.isSuccessful) {
            return result.status === 200
                ? result.data as ITopic[]
                : [];
        }

        throw result.data;
    }

    /**
     * @description Fetches information about a specific topic
     * @param {string} topicId The ID of the topic to be fetched
     */
    async getTopic(topicId: string): Promise<ITopic> {
        const result = await networkService.get<ITopic>(`helpdesk/it/topic/${topicId}`);

        if (result.isSuccessful) {
            return result.data as ITopic;
        }

        throw result.data;
    }
    
    /**
     * @description Gets the IT helpdesk systems
     */
    async getSystems(): Promise<ISystem[]> {
        const result = await networkService.get<ISystem[]>("helpdesk/it/systems");

        if (result.isSuccessful) {
            return result.data as ISystem[];
        }

        throw result.data;
    }

    /**
     * @description Gets the support categories for a system
     * @param {string} systemId The ID of the system to get the categories for
     */
    async getCategories(systemId: string): Promise<ICategory[]> {
        const result = await networkService.get<ICategory[]>(`helpdesk/it/system/${systemId}/categories`);

        if (result.isSuccessful) {
            return result.data as ICategory[];
        }

        throw result.data;
    }

    /**
     * @description Gets the subcategories for a support category
     * @param {string} categoryId The ID of the category to get the subcategories for
     */
    async getSubcategories(categoryId: string): Promise<ISubcategory[]> {
        const result = await networkService.get<ISubcategory[]>(`helpdesk/it/category/${categoryId}/subcategories`);

        if (result.isSuccessful) {
            return result.data as ISubcategory[];
        }

        throw result.data;
    }

    /**
     * @description Fetches the tickets from the IT helpdesk
     */
    async getTickets() : Promise<any> {
        const result = await networkService.get<any>(`helpdesk/it/tickets`);

        if (result.isSuccessful) {
            return result.data;
        }

        throw result.data;
    }

    /**
     * @description Fetches a ticket from the IT helpdesk
     * @param {string} ticketId The ID of the ticket to fetch
     */
    async getTicket(ticketId: number) : Promise<ITicket> {
        const result = await networkService.get<ITicket>(`helpdesk/it/ticket/${ticketId}`);

        if (result.isSuccessful) {
            return result.data as ITicket;
        }

        throw result.data;
    }

    /**
     * @description Creates a ticket in the IT helpdesk
     * @param {ITicket} ticket The ticket to create
     * @returns {number} The ID of the created ticket
     */
    async createTicket(ticket: ITicket) : Promise<number> {
        const result = await networkService.put<ITicket, number>(`helpdesk/it/ticket`, ticket);

        if (result.isSuccessful) {
            return result.data as number;
        }

        throw result.data;
    }

    /**
     * @description Fetches work logged against a helpdesk ticket
     * @param {string} ticketId The ID of the ticket to fetch the work logged for
     */
    async getWorkLogged(ticketId: number) : Promise<IWork[]> {
        const result = await networkService.get<IWork[]>(`helpdesk/it/ticket/${ticketId}/work`);

        if (result.isSuccessful) {
            return result.status === 200
                ? result.data as IWork[]
                : [];
        }

        throw result.data;
    }

    /**
     * @description Submits feedback for a ticket
     * @param {IFeedback} feedback The feedback to submit
     * @returns {boolean} Whether the feedback was submitted successfully
     */
    async submitFeedback(feedback: IFeedback): Promise<boolean> {
        const result = await networkService.post<IFeedback, any>(`helpdesk/it/ticket/feedback`, feedback);
        return result.isSuccessful;
    }

    /**
     * @description Submits an escalation for a ticket
     * @param {number} ticketId The ID of the ticket to escalate
     * @param {string} message The message to include with the escalation
     * @returns {boolean} Whether the escalation was submitted successfully
     */
    async submitEscalation(ticketId: number, message: string): Promise<boolean> {
        const result = await networkService.post<string, any>(`helpdesk/it/ticket/${ticketId}/escalate`, message);
        return result.isSuccessful;
    }

}

const itHelpdeskService = new HelpdeskService();
export default itHelpdeskService;