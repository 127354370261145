import { Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import ISystem from "../../../../../models/helpdesk/it/ISystem";

export const System = ({ system }: { system: ISystem }) => {
    const { t } = useTranslation();
    
    return (
        <Col xs={12}>
            <span className="d-block fw-bold mb-1">{t("it.properties.system")}</span>
            {
                (
                    system.url &&
                    <a href={system.url} target="_blank" rel="noreferrer" className="d-block mb-3">{system.name}</a>
                ) ||
                <span className="d-block mb-3">{system.name}</span>
            }
        </Col>
    );
};