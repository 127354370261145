import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import WelcomeText from "./WelcomeText";
import SupportTiles from "./tiles/SupportTiles";
import ActionTiles from "./tiles/ActionTiles";

export default function Home() {
    const { t } = useTranslation();

    return (
        <Container fluid className="h-100">
            <Row className="mt-2 mb-3 mt-lg-0 mb-lg-0">
                <Col xs={12}>
                    <h1 className="d-block d-lg-none">{t("home.title")}</h1>
                    <WelcomeText />
                </Col>
            </Row>
            <SupportTiles />
            <ActionTiles />
        </Container>
    );
}