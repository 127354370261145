import Status from "../../../enums/Status";
import IDropzoneItem from "../../../models/dropzone/IDropzoneItem";
import ITicket from "../../../models/helpdesk/it/ITicket";
import fileService from "../../../services/fileService";
import itHelpdeskService from "../../../services/itHelpdeskService";

export default async function submitTicket(
    ticket: ITicket,
    files: IDropzoneItem[],
    setStatus: (status: Status) => void
) : Promise<number> {    
    try {
        const attachments = await fileService.convertFilesToAttachments(files.map(f => f.file));
        ticket = { ...ticket, attachments };

        const ticketId = await itHelpdeskService.createTicket(ticket);
        if (ticketId === 0) {
            throw new Error("Failed to create ticket");
        }
        
        setStatus(Status.SAVE_SUCCESS);
        return ticketId;
    } catch {
        setStatus(Status.SAVE_ERROR);
        return 0;
    }
}