import { Alert, Col, ListGroup, Row, Spinner } from "react-bootstrap";
import Status from "../../enums/Status";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import IAsset from "../../models/assets/IAsset";
import deviceService from "../../services/deviceService";
import Laptop from "./Laptop";

export default function Laptops() {
    const { t } = useTranslation();

    const [status, setStatus] = useState<Status>(Status.LOADING);
    const [laptops, setLaptops] = useState<IAsset[]>([]);

    useEffect(() => {
        getLaptops(setStatus, setLaptops);
    }, []);

    if (status === Status.LOADING) {
        return (
            <div className="text-center">
                <Spinner />
                <span className="d-block fw-bold">{t("laps.loading.multiple")}</span>
            </div>
        );
    }

    if (status === Status.LOAD_ERROR) {
        return (
            <Alert variant="danger">
                {t("laps.errors.general")}
            </Alert>
        );
    }

    if (laptops.length === 0) {
        return (
            <Alert variant="info">
                {t("laps.errors.noLaptops")}
            </Alert>
        );
    }

    return (
        <Row>
            <Col xs={12}>
                <ListGroup variant="flush">
                    {
                        laptops
                            .filter((laptop) => laptop.assetType.name === "Laptop")
                            .map((laptop) => <Laptop key={laptop.id} laptop={laptop} />)
                    }
                </ListGroup>
            </Col>
        </Row>
    );
}

async function getLaptops(
    setStatus: (status: Status) => void,
    setLaptops: (laptops: IAsset[]) => void
) {
    try {
        const result = await deviceService.getDevices(true);

        for (const laptop of result) {
            try {
                laptop.laptopName = await deviceService.getDeviceLaptopName(laptop.id) || "Unknown device name";
            } catch (error) {
                laptop.laptopName = "Unknown device name";
            }
        }

        setLaptops(result);
        setStatus(Status.LOAD_SUCCESS);
    } catch (error) {
        setStatus(Status.LOAD_ERROR);
    }
}