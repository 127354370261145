import { Col, Row } from "react-bootstrap";
import LinkTile from "./LinkTile";
import { useTranslation } from "react-i18next";

export default function SupportTiles() {
    const { t } = useTranslation();
    
    return (
        <Row>
            <Col>
                <Row>
                    <h4 className="mb-1">{t("home.sections.support.title")}</h4>
                    <p className="lead">
                        {t("home.sections.support.subtitle")}
                    </p>
                </Row>
                <Row>
                    {
                        process.env.REACT_APP_FEATURE_FLAG_PEOPLE_SUPPORT === "true" &&
                        <LinkTile   titleKey="hr.title"
                                    descriptionKey="hr.description"
                                    link="/hr"
                                    image="/img/tiles/hr_tile.jpg"
                                    />
                    }
                    
                    {
                        process.env.REACT_APP_FEATURE_FLAG_IT_SUPPORT === "true" &&
                        <LinkTile   titleKey="it.title"
                                    descriptionKey="it.description"
                                    link="/it"
                                    image="/img/tiles/it_tile.jpg"
                                    />
                    }

                    <LinkTile   titleKey="fm24.title"
                                descriptionKey="fm24.description"
                                link="https://www.fm24systems.com/"
                                image="/img/tiles/fm24_tile.jpg"
                                />
                </Row>
            </Col>
        </Row>
    );
}