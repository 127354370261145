import { Accordion, Alert, Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import ITicket from "../../../models/helpdesk/it/ITicket";
import { useTranslation } from "react-i18next";
import BackButton from "../../../components/backButton/BackButton";
import { FeedbackType } from "../../../enums/FeedbackType";
import { useState } from "react";
import LargeFloatingTextField from "../../../components/formFields/largeFloatingText/LargeFloatingTextField";
import Status from "../../../enums/Status";
import FormFieldValidation from "../../../enums/FormFieldValidation";
import { FeedbackButton } from "./btnRating/RatingButton";
import IFeedback from "../../../models/helpdesk/it/IFeedback";
import itHelpdeskService from "../../../services/itHelpdeskService";
import { Link } from "react-router-dom";
import { v4 as uuid } from "uuid";

const CardTitle = ({ ticket } : { ticket: ITicket }) => (
    <Card.Title className="d-flex align-items-center justify-content-between mb-3">
        <div className="d-flex align-items-center">
            <BackButton path="/it" />
            <Link to={`/it/ticket/${ticket.id}`} className="text-decoration-none">
                {ticket.ticketNumber}
            </Link>
        </div>
    </Card.Title>
);

export default function FeedbackBody({ ticket } : { ticket: ITicket }) {
    const { t } = useTranslation();

    const [status, setStatus] = useState<Status>(Status.NEUTRAL);
    const [feedback, setFeedback] = useState<IFeedback>({
        ticketId: ticket.id,
        type: FeedbackType.NEUTRAL,
        message: "",
        requestReopen: false
    });

    if (status === Status.SAVE_SUCCESS) {
        return (
            <Card.Body>
                <CardTitle ticket={ticket} />
                <Card.Text as ="div">
                    <Container fluid>
                        <Row>
                            <Col>
                                <Alert variant="success" className="mb-0">
                                    {t("feedback.success")}
                                </Alert>
                            </Col>
                        </Row>
                    </Container>
                </Card.Text>
            </Card.Body>
        );
    }

    const handleFeedback = (type: FeedbackType) => {
        const requestReopen = type !== FeedbackType.NEGATIVE 
            ? false 
            : feedback.requestReopen;

        setFeedback({ ...feedback, type: type, requestReopen: requestReopen });
    }

    const handleSubmit = () => {
        setStatus(Status.SAVING);
        submitFeedback(feedback)
            .then(() => setStatus(Status.SAVE_SUCCESS))
            .catch(() => setStatus(Status.SAVE_ERROR));
    };

    const isReadOnly = [Status.SAVING, Status.SAVE_SUCCESS].includes(status);

    const placeholderText = t(`feedback.feedbackPlaceholders.${feedback.type}`);

    const chkReopenId = `chk-${uuid()}`;

    return (
        <Card.Body>
            <CardTitle ticket={ticket} />
            <Card.Text as="div">
                <Container fluid>
                    <Row>
                        <Col>
                            <h1 className="h2">{ticket.title}</h1>
                            <p className="text-muted">{t("feedback.actionedBy", { name: ticket.assignee!.name })}</p>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Accordion className="w-100">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>{t("feedback.caseDescription")}</Accordion.Header>
                                <Accordion.Body dangerouslySetInnerHTML={{ __html: ticket.description }} />
                            </Accordion.Item>
                        </Accordion>
                    </Row>
                    <Row>
                        <Col>
                            <h3 className="mb-3">
                                {t("feedback.title")}
                            </h3>
                            <FeedbackButton
                                active={feedback.type === FeedbackType.POSITIVE}
                                feedback={FeedbackType.POSITIVE}
                                emoji="HandThumbsUpFill"
                                handleFeedback={handleFeedback}
                                disabled={isReadOnly}
                                />
                            <div className="d-inline-block mx-2" />
                            <FeedbackButton
                                active={feedback.type === FeedbackType.NEGATIVE}
                                feedback={FeedbackType.NEGATIVE}
                                emoji="HandThumbsDownFill"
                                handleFeedback={handleFeedback}
                                disabled={isReadOnly}
                                />
                        </Col>
                    </Row>
                    <Row className="mt-4">
                        <Col>
                            {
                                feedback.type !== FeedbackType.NEUTRAL &&
                                <LargeFloatingTextField
                                    label={placeholderText}
                                    placeholder={placeholderText}
                                    disabled={isReadOnly}
                                    height={200}
                                    type="text"
                                    onChange={(value: string) => setFeedback({ ...feedback, message: value })}
                                    value={feedback.message}
                                    validation={FormFieldValidation.NOT_VALIDATED}
                                    />
                            }
                            {
                                feedback && feedback.type === FeedbackType.NEGATIVE &&
                                <div key={chkReopenId} className="mb-3">
                                    <Form.Check type="checkbox"
                                                id={chkReopenId}
                                                label={t("feedback.reopen")}
                                                checked={feedback.requestReopen}
                                                onChange={() => setFeedback({ ...feedback, requestReopen: !feedback.requestReopen })}
                                                disabled={isReadOnly}
                                                />
                                </div>
                            }
                            {
                                status === Status.SAVING &&
                                <Alert variant="info">
                                    {t("general.pleaseWait")}
                                </Alert>
                            }
                            {
                                status === Status.SAVE_ERROR &&
                                <Alert variant="danger">
                                    {t("feedback.errors.general")}
                                </Alert>
                            }
                            {
                                feedback &&
                                <Button variant="primary" onClick={handleSubmit}>
                                    {t("feedback.submit")}
                                </Button>
                            }
                        </Col>
                    </Row>
                </Container>
            </Card.Text>
        </Card.Body>
    );
}

async function submitFeedback(feedback: IFeedback) {
    const result = await itHelpdeskService.submitFeedback(feedback);
    return result;
}