import { Alert, Button, Card, Col, Row } from "react-bootstrap";
import { Step } from "./progress/Step";
import Status from "../../../enums/Status";
import { useTranslation } from "react-i18next";
import { PlatformSection } from "./platforms/PlatformSection";
import { TopicSection } from "./topics/TopicSection";
import SelfHelpSection from "./self-help/SelfHelpSection";
import DetailsSection from "./details/Details";
import ITicket from "../../../models/helpdesk/it/ITicket";
import ITicketValidation from "./details/ITicketValidation";
import IPlatform from "../../../models/helpdesk/it/IPlatform";
import ITopic from "../../../models/helpdesk/it/ITopic";
import IDropzoneItem from "../../../models/dropzone/IDropzoneItem";

interface INewTicketBodyProps {
    dropzoneFiles: IDropzoneItem[];
    handleInputChange: (prop: keyof ITicket, value: any) => void;
    handleSubmit: () => void;
    isReadOnly: boolean;
    onPlatformClick: (platform: IPlatform) => void;
    onQuestionAnswered: (questionId: number, answer: string) => void;
    onSelfHelpContinue: () => void;
    onTopicClick: (topic: ITopic) => void;
    setDropzoneFiles: (files: IDropzoneItem[]) => void;
    step: Step;
    status: Status;
    ticket: ITicket;
    validation: ITicketValidation;
}

export default function NewTicketBody(props: INewTicketBodyProps) {
    const { t } = useTranslation();
    const { dropzoneFiles, handleInputChange, handleSubmit, isReadOnly, onPlatformClick, onQuestionAnswered, onSelfHelpContinue, onTopicClick, setDropzoneFiles, status, step, ticket, validation } = props;
    return (
        <Card.Text as="div">
            {
                {
                    PLATFORMS:  <PlatformSection
                                    isReadOnly={isReadOnly}
                                    onClick={onPlatformClick}
                                    />,
                    TOPICS:     <TopicSection
                                    isReadOnly={isReadOnly}
                                    platform={ticket.platform!}
                                    onClick={onTopicClick}
                                    />,
                    SELF_HELP:  <SelfHelpSection
                                    isReadOnly={isReadOnly}
                                    platform={ticket.platform!}
                                    topic={ticket.topic!}
                                    continue={onSelfHelpContinue}
                                    />,
                    DETAILS:    <DetailsSection    
                                    ticket={ticket}
                                    isReadOnly={isReadOnly}
                                    dropzoneFiles={dropzoneFiles}
                                    validation={validation}
                                    onInputChange={handleInputChange}
                                    onQuestionAnswerChange={onQuestionAnswered}
                                    setDropzoneFiles={setDropzoneFiles}
                                    />
                }[step]
            }
            {
                step === Step.DETAILS && status === Status.SAVING &&
                <Alert variant="info">
                    {t("ticket.form.saving")}
                </Alert>
            }
            {
                step === Step.DETAILS && status === Status.SAVE_SUCCESS &&
                <Alert variant="success">
                    {t("ticket.form.saved")}
                </Alert>
            }
            {
                step === Step.DETAILS && status === Status.SAVE_ERROR &&
                <Alert variant="danger">
                    {t("ticket.form.errors.general")}
                </Alert>
            }
            {
                step === Step.DETAILS &&
                <Row className="justify-content-center">
                    <Col xs={12} md={10} lg={6} xl={4}>
                        <div className="d-grid">
                            <Button type="button" size="lg" variant="primary" disabled={isReadOnly} onClick={handleSubmit}>
                                {t("general.submit")}
                            </Button>
                        </div>
                    </Col>
                </Row>
            }
            </Card.Text>
    );
}