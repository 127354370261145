import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import IOnboarding from "../../models/hr/onboarding/IOnboarding";

export default function EmployeeDetails({ onboarding} : { onboarding: IOnboarding }) {
    const { t } = useTranslation();

    return (
        <Row>
            <Col>
                <h2 className="h5">
                    {onboarding!.name}
                </h2>
                <ul>
                    {
                        onboarding?.number &&
                        <li>
                            {t("onboarding.details.rows.number", { number: onboarding!.number })}
                        </li>
                    }
                    <li>
                        {t("onboarding.details.rows.position", { position: onboarding!.jobTitle })}
                    </li>
                    <li>
                        {t("onboarding.details.rows.team", { team: onboarding!.team })}
                    </li>
                    <li>
                        {t("onboarding.details.rows.department", { department: onboarding!.department })}
                    </li>
                    <li>
                        {t("onboarding.details.rows.lineManager", { lineManager: onboarding!.lineManager })}
                    </li>
                    <li>
                        {t("onboarding.details.rows.country", { country: onboarding!.country })}
                    </li>
                </ul>
            </Col>
        </Row>
    );
}