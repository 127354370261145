import dayjs from "dayjs";
import IMessage from "../../models/helpdesk/comms/IMessage";
import { ListGroup } from "react-bootstrap";
import { v4 as uuid } from "uuid";
import { Icon } from "../../components/icon/Icon";

export default function Message({ message } : { message: IMessage }) {
    const today = dayjs().format("DD/MM/YYYY");
    const date = dayjs(message.sent).format("DD/MM/YYYY") === today 
        ? dayjs(message.sent).format("HH:mm")
        : dayjs(message.sent).format("DD/MM/YYYY HH:mm");

    return (
        <ListGroup.Item key={uuid()}>
            <div className="fw-bold">{message.subject}</div>
            <div className="small text-muted">
                <Icon iconName="PersonCircle" endMargin={2} />
                <span>{message.sender.name}</span>
            </div>
            <div className="small text-muted">
                <Icon iconName="Calendar2Fill" endMargin={2} />
                <span>{date}</span>
            </div>
            <div dangerouslySetInnerHTML={{ __html: message.body }} />
        </ListGroup.Item>
    );
}