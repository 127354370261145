import { useTranslation } from "react-i18next";
import { Col, Container, Row } from "react-bootstrap";
import Articles from "./articles/Articles";

export default function KnowledgeBase() {
    const { t } = useTranslation();

    return (
        <Container fluid>
            <Row className="mb-3">
                <Col>
                    <h1>{t("it.kb.title")}</h1>
                    <p className="lead">{t("it.kb.description")}</p>
                </Col>
            </Row>
            <Row className="mt-1">
                <Col>
                    <Articles />
                </Col>
            </Row>
        </Container>
    );
}