/* eslint-disable react/jsx-pascal-case */
import { ReactNode } from "react";
import { Col, Container, ListGroup, Row } from "react-bootstrap";
import ITicket from "../../../models/helpdesk/it/ITicket";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import styles from "./Ticket.module.scss";

interface ITicketProps {
    ticket: ITicket;
}

const CellContent = ({ children } : { children: ReactNode }) => (
    <div className={styles.flex}>
        <div className={styles.block}>
            {children}
        </div>
    </div>
);

export default function Ticket(props: ITicketProps) {
    const { ticket } = props;
    const { t } = useTranslation();

    const created = dayjs(ticket.created!).format("DD/MM/YYYY");

    return (
        <ListGroup.Item action key={ticket.id} as={Link} to={`/it/ticket/${ticket.id}`} className="py-0">
            <Container fluid>
                <Row className="align-items-stretch">
                    <Col xs={12} lg={4} className={styles.cell}>
                        <CellContent>
                            <span className="d-block fw-bold mb-2 mb-lg-0">{ticket.ticketNumber!}</span>

                            <span className="d-block d-lg-none fw-bold">{t("it.my.table.headers.title")}</span>
                            <span className="d-block">{ticket.title}</span>
                        </CellContent>
                    </Col>

                    <Col xs={12} lg={3} className={styles.cell}>
                        <CellContent>
                            <span className="d-block d-lg-none fw-bold">{t("it.my.table.headers.created")}</span>
                            <span className="d-block">{created}</span>
                        </CellContent>
                    </Col>

                    <Col xs={12} lg={2} className={styles.cell}>
                        <CellContent>
                            <span className="d-block d-lg-none fw-bold">{t("it.my.table.headers.status")}</span>
                            <span className="d-block">{ticket.status!.name}</span>
                        </CellContent>
                    </Col>

                    <Col xs={12} lg={3} className={styles.cell}>
                        <CellContent>
                            <span className="d-block d-lg-none fw-bold">{t("it.my.table.headers.details")}</span>
                            {
                                (ticket.platform && ticket.topic && <TicketDetails__New ticket={ticket} />) ||
                                (ticket.system && ticket.category && <TicketDetails__Old ticket={ticket} />) ||
                                <span dangerouslySetInnerHTML={{ __html: t("it.my.table.detailsCell.uncategorised") }} />
                            }
                        </CellContent>
                    </Col>
                </Row>
            </Container>
        </ListGroup.Item>
    );
}

const TicketDetails__Old = (props : ITicketProps) => {
    const ticket = props.ticket;
    const { t } = useTranslation();

    const htmlCategory = ticket.subcategory
        ? t("it.my.table.detailsCell.categoryWithSubcategory", { category: ticket.category!.name, subcategory: ticket.subcategory!.name })
        : t("it.my.table.detailsCell.category", { category: ticket.category!.name });

    return (
        <ul className="my-1">
            <li dangerouslySetInnerHTML={{ __html: t("it.my.table.detailsCell.system", { system: ticket.system!.name })}} />
            <li dangerouslySetInnerHTML={{ __html: htmlCategory }} />
        </ul>
    );
};

const TicketDetails__New = (props : ITicketProps) => {
    const ticket = props.ticket;
    const { t } = useTranslation();

    return (
        <ul className="my-1">
            <li dangerouslySetInnerHTML={{ __html: t("it.my.table.detailsCell.platform", { platform: ticket.platform!.name })}} />
            <li dangerouslySetInnerHTML={{ __html: t("it.my.table.detailsCell.topic", { topic: ticket.topic!.name })}} />
        </ul>
    );
};