import { Col, Row } from "react-bootstrap";
import ITicket from "../../../../../models/helpdesk/hr/ITicket";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

export default function DetailsTab({ ticket }: { ticket: ITicket }) {
    const { t } = useTranslation();
    
    const required = ticket.required
        ? dayjs(ticket.required).format("DD/MM/YYYY")
        : "";

    return (
        <Row>
            <Col>
                <Row className="border-bottom mb-3">
                    <Col>
                        <div dangerouslySetInnerHTML={{ __html: ticket.description }} />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <span className="d-block fw-bold mb-1">{t("hr.properties.category")}</span>
                        <span className="d-block mb-3">
                            <span className="d-inline">{ticket.category!.name}</span>
                            {ticket.subcategory && <span className="d-inline">&nbsp;- {ticket.subcategory.name}</span>}
                        </span>
                    </Col>
                </Row>
                {
                    required &&
                    <Row>
                        <Col>
                            <span className="d-block fw-bold mb-1">{t("hr.properties.required")}</span>
                            <span className="d-block mb-3">
                                <span className="d-inline">{required}</span>
                            </span>
                        </Col>
                    </Row>
                }
            </Col>
        </Row>
    );
}