import { Alert, Button, Col } from "react-bootstrap";
import { Row } from "react-bootstrap";
import { Form } from "react-bootstrap";
import FloatingTextField from "../../../components/formFields/floatingText/FloatingTextField";
import RichTextField from "../../../components/formFields/richText/RichTextField";
import FormFieldValidation from "../../../enums/FormFieldValidation";
import { useEffect, useState } from "react";
import ITicket from "../../../models/helpdesk/hr/ITicket";
import { useTranslation } from "react-i18next";
import Status from "../../../enums/Status";
import ICategory from "../../../models/helpdesk/ICategory";
import ISubcategory from "../../../models/helpdesk/ISubcategory";
import { useNavigate } from "react-router-dom";
import DropdownSelect from "../../../components/formFields/dropdownSelect/DropdownSelect";
import hrHelpdeskService from "../../../services/hrHelpdeskService";
import Dropzone from "../../../components/formFields/dropzone/Dropzone";
import IDropzoneItem from "../../../models/dropzone/IDropzoneItem";
import { v4 as uuid } from "uuid";
import fileService from "../../../services/fileService";

interface ITicketValidation {
    title: FormFieldValidation;
    category: FormFieldValidation;
    subcategory: FormFieldValidation;
    description: FormFieldValidation;
}

export default function NewTicketForm() {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const handleSubmit = (ev: React.FormEvent<HTMLFormElement>) => {
        ev.preventDefault();
        const isValid = validate(ticket, showSubcategories, setValidation);

        if (!isValid) {
            return;
        }

        setStatus(Status.SAVING);

        submitTicket(ticket, dropzoneFiles)
            .then(ticketId => {
                if (ticketId > 0) {
                    setStatus(Status.SAVE_SUCCESS);
                    setTimeout(() => navigate(`/hr/ticket/${ticketId}`), 750);
                } else {
                    setStatus(Status.SAVE_ERROR);
                }
            })
            .catch(() => setStatus(Status.SAVE_ERROR));
    };

    const [status, setStatus] = useState<Status>(Status.LOADING);
    const [isReadOnly, setIsReadOnly] = useState<boolean>(false);
    const [categories, setCategories] = useState<ICategory[]>([]);
    const [subcategories, setSubcategories] = useState<ISubcategory[]>([]);
    const [showSubcategories, setShowSubcategories] = useState<boolean>(false);

    const [dropzoneFiles, setDropzoneFiles] = useState<IDropzoneItem[]>([]);
    const [ticket, setTicket] = useState<ITicket>({
        id: 0,
        title: "",
        description: "",
        category: null!,
        subcategory: null!,
        attachments: []
    });

    const [validation, setValidation] = useState<ITicketValidation>({
        title: FormFieldValidation.NOT_VALIDATED,
        category: FormFieldValidation.NOT_VALIDATED,
        subcategory: FormFieldValidation.NOT_VALIDATED,
        description: FormFieldValidation.NOT_VALIDATED
    });

    useEffect(() => setIsReadOnly([Status.SAVE_SUCCESS, Status.SAVING, Status.LOADING, Status.LOAD_ERROR].includes(status)), [status]);

    useEffect(() => {
        getCategories(categories => {
            setCategories(categories);
            setStatus(Status.LOAD_SUCCESS);
        })
        .catch(() => setStatus(Status.LOAD_ERROR));
    }, []);

    const hasValidationErrors = Object.values(validation).some(v => v === FormFieldValidation.INVALID);
    
    const handleInputChange = (prop: keyof ITicket, value: any) => {
        setTicket(ticket => ({ ...ticket, [prop]: value }));
        setValidation(validation => ({ ...validation, [prop]: FormFieldValidation.NOT_VALIDATED }));
    };

    const handleCategoryChange = (category: ICategory) => {
        setTicket({ ...ticket, "category": category, "subcategory": null! });
        
        setStatus(Status.LOADING);
        getSubcategories(category.id, setSubcategories, setShowSubcategories)
            .then(() => setStatus(Status.LOAD_SUCCESS))
            .catch(() => setStatus(Status.LOAD_ERROR))
            .finally(() => {
                setValidation(validation => ({ ...validation, "subcategory": FormFieldValidation.NOT_VALIDATED}));
            });
    };

    const handleFileDrop = (acceptedFiles: File[]) => {
        const newFiles = acceptedFiles.map(file => ({ id: uuid(), file }));
        setDropzoneFiles(files => [...files, ...newFiles]);
    };

    const handleFileRemove = (id: string) => setDropzoneFiles(files => files.filter(f => f.id !== id));

    const colCategoryWidth = showSubcategories ? 6 : 12;

    return (
        <Form onSubmit={handleSubmit}>
            <Row>
                <Col>
                    <FloatingTextField
                        label={t("ticket.form.fields.subject.label")}
                        type="text"
                        placeholder={t("ticket.form.fields.subject.placeholder")}
                        value={ticket.title}
                        disabled={isReadOnly}
                        onChange={value => handleInputChange("title", value)}
                        validation={validation.title}
                        validationMessage={t("ticket.form.fields.subject.validation")}
                        />
                </Col>
            </Row>
            <Row className="gx-3">
                <Col xs={12} lg={colCategoryWidth}>
                    <DropdownSelect 
                        title={t("ticket.form.fields.category.label")}
                        items={categories}
                        value={ticket.category?.id!}
                        itemValueField="id"
                        itemLabelField="name"
                        valueLabel={ticket.category?.name!}
                        validation={validation.category}
                        validationMessage={t("ticket.form.fields.category.validation")}
                        disabled={isReadOnly}
                        onchange={(cat: ICategory) => handleCategoryChange(cat)} />
                </Col>
                {
                    showSubcategories &&
                    <Col xs={12} lg={colCategoryWidth}>
                        <DropdownSelect 
                            title={t("ticket.form.fields.subcategory.label")}
                            items={subcategories}
                            value={ticket.subcategory?.id!}
                            itemValueField="id"
                            itemLabelField="name"
                            valueLabel={ticket.subcategory?.name!}
                            validation={validation.subcategory}
                            validationMessage={t("ticket.form.fields.subcategory.validation")}
                            disabled={isReadOnly}
                            onchange={(sub: ISubcategory) => handleInputChange("subcategory", sub)} />
                    </Col>
                }
            </Row>
            <Row>
                <Col>
                    <RichTextField
                        value={ticket.description}
                        placeholder={t("ticket.form.fields.description.label")}
                        disabled={isReadOnly}
                        onChange={value => handleInputChange("description", value)}
                        validation={validation.description}
                        validationMessage={t("ticket.form.fields.description.validation")}
                        />
                </Col>
            </Row>

            <Row>
                <Col>
                    <Dropzone
                        items={dropzoneFiles}
                        disabled={isReadOnly}
                        onDrop={handleFileDrop}
                        onRemove={handleFileRemove}
                        />
                </Col>
            </Row>

            <Row>
                <Col>
                    {
                        hasValidationErrors &&
                        <Alert variant="danger">
                            {t("ticket.form.errors.validation")}
                        </Alert>
                    }
                    {
                        status === Status.SAVING &&
                        <Alert variant="info">
                            {t("ticket.form.saving")}
                        </Alert>
                    }
                    {
                        status === Status.SAVE_SUCCESS &&
                        <Alert variant="success">
                            {t("ticket.form.saved")}
                        </Alert>
                    }
                    {
                        status === Status.SAVE_ERROR &&
                        <Alert variant="danger">
                            {t("ticket.form.errors.general")}
                        </Alert>
                    }
                </Col>
            </Row>
            
            <Row className="justify-content-center">
                <Col xs={12} md={10} lg={6} xl={4}>
                    <div className="d-grid">
                        <Button type="submit" size="lg" variant="primary" disabled={isReadOnly}>{t("general.submit")}</Button>
                    </div>
                </Col>
            </Row>
        </Form>
    );
}

async function getCategories(setCategories: (categories: ICategory[]) => void) {
    const categories = await hrHelpdeskService.getCategories();
    setCategories(categories);
}

async function getSubcategories(
    categoryId: string,
    setSubcategories: (subcategories: ISubcategory[]) => void,
    setShowSubcategories: (show: boolean) => void
) {
    const subcategories = await hrHelpdeskService.getSubcategories(categoryId);
    setSubcategories(subcategories);
    setShowSubcategories(subcategories.length > 0);
}

async function submitTicket(ticket: ITicket, files: IDropzoneItem[]) : Promise<number> {
    try {
        const attachments = await fileService.convertFilesToAttachments(files.map(f => f.file));
        ticket = { ...ticket, attachments };

        const ticketId = await hrHelpdeskService.createTicket(ticket);
        return ticketId;
    } catch {
        return 0;
    }
}

function validate(
    ticket: ITicket,
    showSubcategories: boolean,
    setValidation: (validation: ITicketValidation) => void
) {
    const validation: ITicketValidation = {
        title: ticket.title.length > 0 
            ? FormFieldValidation.VALID
            : FormFieldValidation.INVALID,
        category: ticket.category !== null
            ? FormFieldValidation.VALID
            : FormFieldValidation.INVALID,
        subcategory: !showSubcategories || ticket.subcategory !== null
            ? FormFieldValidation.VALID
            : FormFieldValidation.INVALID,
        description: ticket.description.length > 0
            ? FormFieldValidation.VALID
            : FormFieldValidation.INVALID
    };

    setValidation(validation);

    return Object.values(validation).every(v => v === FormFieldValidation.VALID);
}