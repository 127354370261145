import { Alert, ListGroup } from "react-bootstrap";
import { ITicket } from "../../../../models/helpdesk/ITicket";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import Status from "../../../../enums/Status";
import IAttachment from "../../../../models/attachments/IAttachment";
import attachmentService from "../../../../services/attachmentService";
import { v4 as uuid } from "uuid";
import Attachment from "./Attachment";
import UploadArea from "./upload/UploadArea";

export default function AttachmentsTab({ ticket }: { ticket: ITicket }) {
    const ticketId = ticket.id;

    const { t } = useTranslation();
    
    const [status, setStatus] = useState<Status>(Status.LOADING);
    const [attachments, setAttachments] = useState<IAttachment[]>([]);

    const fetchAttachments = async (ticketId: number) => {
        try {
            setStatus(Status.LOADING);

            let attachments = await attachmentService.getAttachments(ticketId);
            attachments = attachments.sort((a, b) => a.name.localeCompare(b.name));

            setAttachments(attachments);
            setStatus(Status.LOAD_SUCCESS);
        } catch {
            setStatus(Status.LOAD_ERROR);
        }
    };

    useEffect(() => { fetchAttachments(ticketId); }, [ticketId]);

    if (status === Status.LOADING) {
        return <Alert variant="info">{t("general.loading")}</Alert>;
    }

    if (status === Status.LOAD_ERROR) {
        return <Alert variant="danger">{t("attachments.errors.generalFetch")}</Alert>;
    }

    if (attachments.length === 0) {
        return (
            <>
                <Alert variant="info">{t("attachments.errors.noAttachments")}</Alert>
                <UploadArea ticketId={ticketId} onUpload={() => fetchAttachments(ticketId)} />
            </>
        );
    }

    return (
        <>
            <ListGroup className="mb-3">
                {attachments.map(attachment => <Attachment key={uuid()} attachment={attachment} />)}
            </ListGroup>
            <UploadArea ticketId={ticketId} onUpload={() => fetchAttachments(ticketId)} />
        </>
    );
}