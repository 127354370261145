import { useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Step } from "./progress/Step";
import IPlatform from "../../../models/helpdesk/it/IPlatform";
import ITopic from "../../../models/helpdesk/it/ITopic";
import Status from "../../../enums/Status";
import ITicket from "../../../models/helpdesk/it/ITicket";
import IDropzoneItem from "../../../models/dropzone/IDropzoneItem";
import BackButton from "../../../components/backButton/BackButton";
import ITicketValidation from "./details/ITicketValidation";
import FormFieldValidation from "../../../enums/FormFieldValidation";
import { useNavigate } from "react-router-dom";
import submitTicket from "./Submit";
import validate from "./Validate";
import NewTicketBody from "./NewTicketBody";

/**
 * @description This component is used to display the pre-submission form for the IT helpdesk, to gather information from the user before proceeding to log a request.
 */
export function NewTicket() {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [step, setStep] = useState<Step>(Step.PLATFORMS);
    const [status, setStatus] = useState<Status>(Status.NEUTRAL);
    const [dropzoneFiles, setDropzoneFiles] = useState<IDropzoneItem[]>([]);

    const [validation, setValidation] = useState<ITicketValidation>({
        title: FormFieldValidation.NOT_VALIDATED,
        description: FormFieldValidation.NOT_VALIDATED,
        questions: FormFieldValidation.NOT_VALIDATED
    });

    const [ticket, setTicket] = useState<ITicket>({
        id: 0,
        title: "",
        description: "",
        platform: null!,
        topic: null!,
        isEligibleForFeedback: false,
        hasBeenEscalated: false,
        questions: [],
        attachments: []
    });

    const onPlatformClick = (platform: IPlatform) => {
        setTicket(ticket => ({ ...ticket, platform }));
        setStep(Step.TOPICS);
    };

    const onTopicClick = (topic: ITopic) => {
        setTicket(ticket => ({ ...ticket, topic, questions: topic.questions }));
        
        const step = topic.articles.length > 0 
            ? Step.SELF_HELP 
            : Step.DETAILS;

        setStep(step);
    };

    const onSelfHelpContinue = () => setStep(Step.DETAILS);

    const handleInputChange = (prop: keyof ITicket, value: any) => {
        setTicket(ticket => ({ ...ticket, [prop]: value }));
        setValidation(validation => ({ ...validation, [prop]: FormFieldValidation.NOT_VALIDATED }));
    };

    const onQuestionAnswered = (questionId: number, answer: string) => {
        const updatedQuestions = ticket.questions.map(q => q.id === questionId ? { ...q, answer } : q);
        setTicket(ticket => ({ ...ticket, questions: updatedQuestions }));
    };

    const handleSubmit = () => {
        const isValid = validate(ticket, setValidation);
        if (!isValid) {
            return;
        }

        setStatus(Status.SAVING);
        submitTicket(ticket, dropzoneFiles, setStatus)
            .then(ticketId => {
                if (ticketId > 0) {
                    setTimeout(() => navigate(`/it/ticket/${ticketId}`), 1_000);
                }
            });
    };

    const isReadOnly = [Status.LOADING, Status.LOAD_ERROR, Status.SAVING, Status.SAVE_SUCCESS].includes(status);
    
    return (
        <Container className="py-3">
            <Row className="justify-content-center">
                <Col>
                    <Card className="shadow">
                        <Card.Body>
                            <Card.Title className="d-flex align-items-center mb-3">
                                <BackButton path="/it" />
                                <span>{t("ticket.form.title.it")}</span>
                            </Card.Title>
                            <NewTicketBody
                                dropzoneFiles={dropzoneFiles}
                                handleInputChange={handleInputChange}
                                handleSubmit={handleSubmit}
                                isReadOnly={isReadOnly}
                                onPlatformClick={onPlatformClick}
                                onQuestionAnswered={onQuestionAnswered}
                                onSelfHelpContinue={onSelfHelpContinue}
                                onTopicClick={onTopicClick}
                                setDropzoneFiles={setDropzoneFiles}
                                step={step}
                                status={status}
                                ticket={ticket}
                                validation={validation}
                            />
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}