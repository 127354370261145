import { Col } from "react-bootstrap";
import Tile from "../../../../../components/tile/Tile";
import IPlatformTileProps from "./IPlatformTileProps";

const baseUrl = process.env.REACT_APP_API_BASE_URL || "/api/";

const TileImageUrl = (id: string) => `${baseUrl}helpdesk/it/platform/${id}/image`;

export const PlatformTile = (props: IPlatformTileProps) => (
    <Col xs={12} md={6} lg={4} className="mb-3">
        <Tile   title={props.platform.name}
                description={props.platform.subtitle}
                onClick={props.onClick}
                image={TileImageUrl(props.platform.id)}
                />
    </Col>
);