import IAttachment from "../models/attachments/IAttachment";

class FileService {

    public convertFilesToAttachments = (files: File[]): Promise<IAttachment[]> => Promise.all(files.map(this.readFile));

    private readFile(file: File): Promise<IAttachment> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => {
                let content = reader.result as string;
                content = content.split(",")[1];
    
                resolve({
                    id: 0,
                    dateCreated: new Date(),
                    name: file.name,
                    size: file.size,
                    type: file.type,
                    content
                });
            };
            reader.onerror = reject;
            reader.readAsDataURL(file);
        });
    };

}

const fileService = new FileService();
export default fileService;