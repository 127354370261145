import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './app/App';
import reportWebVitals from './reportWebVitals';
import initialiseTranslations from './locales/initialise';
import 'bootstrap/dist/css/bootstrap.min.css';
import { MsalProvider } from '@azure/msal-react';
import msalInstance from './msalConfig';

initialiseTranslations();

const rootElement = document.getElementById('root') as HTMLElement;
const root = ReactDOM.createRoot(rootElement);

const app = (
    <MsalProvider instance={msalInstance}>
        <App />
    </MsalProvider>
)

root.render(<React.StrictMode children={app} />);

// TODO: Function to post results to API endpoint
reportWebVitals(console.log);