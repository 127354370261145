import IApproval from "../models/approvals/IApproval";
import IDecision from "../models/approvals/IDecision";
import networkService from "./networkService";

class ApprovalService {

    /**
     * @description Fetches all approval requests for the current user
     */
    async getRequests() {
        const result = await networkService.get<IApproval[]>("approvals");

        if (result.status === 200) {
            return result.data as IApproval[];
        } else if (result.status === 204) {
            return [];
        }

        throw result.data;
    }

    /**
     * @description Fetches a specific approval request by its ID
     * @param {number} requestId The ID of the request to be returned
     */
    async getRequest(requestId: number) {
        const result = await networkService.get<IApproval>(`approvals/${requestId}`);

        if (result.isSuccessful) {
            return result.data as IApproval;
        }

        throw result.data;
    }

    async postDecision(decision: IDecision) : Promise<boolean> {
        const result = await networkService.post(`approvals/${decision.requestId}`, decision);
        return result.isSuccessful;
    }

}

const approvalService = new ApprovalService();
export default approvalService;