import { ReactNode } from "react";
import { Col, Container, ListGroup, Row } from "react-bootstrap";
import ITicket from "../../../models/helpdesk/hr/ITicket";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import styles from "./Ticket.module.scss";

interface ITicketProps {
    ticket: ITicket;
}

const CellContent = ({ children } : { children: ReactNode }) => (
    <div className={styles.flex}>
        <div className={styles.block}>
            {children}
        </div>
    </div>
);

export default function Ticket(props: ITicketProps) {
    const { ticket } = props;
    const { t } = useTranslation();

    const created = dayjs(ticket.created!).format("DD/MM/YYYY");
    let category = ticket.category?.name;
    if (ticket.subcategory) {
        category += ` - ${ticket.subcategory.name}`;
    }

    const required = ticket.required
        ? dayjs(ticket.required).format("DD/MM/YYYY")
        : "";

    return (
        <ListGroup.Item action key={ticket.id} as={Link} to={`/hr/ticket/${ticket.id}`} className="py-0">
            <Container fluid>
                <Row className="align-items-stretch">
                    <Col xs={12} lg={3} className={styles.cell}>
                        <CellContent>
                            <span className="d-block fw-bold mb-2 mb-lg-0">{ticket.ticketNumber!}</span>

                            <span className="d-block d-lg-none fw-bold">{t("hr.my.table.headers.title")}</span>
                            <span className="d-block">{ticket.title}</span>
                        </CellContent>
                    </Col>

                    <Col xs={12} lg={2} className={styles.cell}>
                        <CellContent>
                            <span className="d-block d-lg-none fw-bold">{t("hr.my.table.headers.created")}</span>
                            <span className="d-block">{created}</span>
                        </CellContent>
                    </Col>

                    <Col xs={12} lg={2} className={styles.cell}>
                        <CellContent>
                            <span className="d-block d-lg-none fw-bold">{t("hr.my.table.headers.status")}</span>
                            <span className="d-block">{ticket.status!.name}</span>
                        </CellContent>
                    </Col>

                    <Col xs={12} lg={3} className={styles.cell}>
                        <CellContent>
                            <span className="d-block d-lg-none fw-bold">{t("hr.my.table.headers.category")}</span>
                            <span className="d-block">{category}</span>
                        </CellContent>
                    </Col>

                    <Col xs={12} lg={2} className={styles.cell}>
                        <CellContent>
                            <span className="d-block d-lg-none fw-bold">{t("hr.my.table.headers.required")}</span>
                            <span className="d-block">{required}</span>
                        </CellContent>
                    </Col>
                </Row>
            </Container>
        </ListGroup.Item>
    );
}