import { useState } from "react";
import dayjs from "dayjs";
import IAttachment from "../../../../models/attachments/IAttachment";
import { Button, ListGroup, OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import { Icon } from "../../../../components/icon/Icon";
import { useTranslation } from "react-i18next";
import Status from "../../../../enums/Status";
import attachmentService from "../../../../services/attachmentService";

export default function Attachment({ attachment }: { attachment: IAttachment }) {
    const { t } = useTranslation();

    const [status, setStatus] = useState<Status>(Status.NEUTRAL);
    
    const date = dayjs(attachment.dateCreated).format("DD/MM/YYYY");
    const size = attachment.size
        ? formatFileSize(attachment.size!)
        : "0 B";

    const onDownload = () => {
        setStatus(Status.LOADING);
        downloadAttachment(attachment, setStatus);
    };

    return (
        <ListGroup.Item action>
            <div className="d-flex align-items-center">
                <div className="d-block">
                    <div>{attachment.name}</div>
                    <div className='small'>
                        <span className='d-inline-block me-2'>{date}</span>
                        {size !== "0 B" && <span className='d-inline-block text-muted'>{size}</span>}
                    </div>
                </div>
                <div className="ms-auto">
                    {
                        status === Status.NEUTRAL &&
                        <OverlayTrigger overlay={<Tooltip id="tooltip-download">{t("general.download")}</Tooltip>} placement='left'>
                            <Button variant='outline-primary' onClick={onDownload}>
                                <Icon iconName='Download' />
                            </Button>
                        </OverlayTrigger>
                    }
                    {
                        status === Status.LOADING &&
                        <Button variant="primary" disabled>
                            <Spinner size="sm" />
                        </Button>
                    }
                    {
                        status === Status.LOAD_SUCCESS &&
                        <Button variant='success' disabled>
                            <Icon iconName='Check2' />
                        </Button>
                    }
                    {
                        status === Status.LOAD_ERROR &&
                        <OverlayTrigger overlay={<Tooltip id="tooltip-error">{t("attachments.errors.generalDownload")}</Tooltip>} placement='left'>
                            <Button variant='outline-danger border-0' onClick={onDownload}>
                                <Icon iconName='ExclamationTriangleFill' />
                            </Button>
                        </OverlayTrigger>
                    }
                </div>
            </div>
        </ListGroup.Item>
    );
}

async function downloadAttachment(attachment: IAttachment, setStatus: (status: Status) => void) {
    const result = await attachmentService.downloadAttachment(attachment.id);

    const link = document.createElement('a');
    link.href = URL.createObjectURL(result);
    link.download = attachment.name;

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    
    setStatus(Status.LOAD_SUCCESS);
}

const formatFileSize = (size: number) => {
    if (size < 1024) return `${size} B`;
    if (size < 1024 * 1024) return `${(size / 1024).toFixed(2)} KB`;
    return `${(size / (1024 * 1024)).toFixed(2)} MB`;
};