import { ListGroup, ListGroupItem } from "react-bootstrap";
import IQuestion from "../../../../../../models/helpdesk/it/IQuestion";
import styles from "./Questions.module.scss";
import { useTranslation } from "react-i18next";

export default function Questions({ questions }: { questions: IQuestion[] }) {
    const { t } = useTranslation();
    
    return (
        <div className="d-block">
            <p className="lead mb-1">{t("it.questionsHeader")}</p>
            <ListGroup>
                {
                    questions.map((question, index) => (
                        <ListGroupItem key={index}>
                            <p className="mb-1 fw-bold">
                                {question.text}
                            </p>
                            <p className={`text-muted mb-0 ${styles.answer}`}>{question.answer}</p>
                        </ListGroupItem>
                    ))
                }
            </ListGroup>
        </div>
    );
}