import CreateGraphClient, { getAccessToken } from "./MicrosoftGraphClient";

class UserService {

    /**
     * @description Get the user's photo
     * @param {string} username The username of the user to get the photo for
     */
    async getPhoto(username: string) {
        const accessToken = await getAccessToken();
        const client = CreateGraphClient(accessToken!);
        
        const photo = await client.photo(username);
        return photo;
    }

}

const userService = new UserService();
export default userService;