/**
 * @description Entity status flags
 */
enum Status {

    /**
     * @description Neutral status flag
     */
    NEUTRAL = 0,

    /**
     * @description Loading status flag
     */
    LOADING,

    /**
     * @description Loading failed status flag
     */
    LOAD_ERROR,

    /**
     * @description Loading succeeded status flag
     */
    LOAD_SUCCESS,

    /**
     * @description Saving status flag
     */
    SAVING,

    /**
     * @description Saving failed status flag
     */
    SAVE_ERROR,

    /**
     * @description Saving succeeded status flag
     */
    SAVE_SUCCESS,

    /**
     * @description Deleting status flag
     */
    DELETING,

    /**
     * @description Deleting failed status flag
     */
    DELETE_ERROR,

    /**
     * @description Deleting succeeded status flag
     */
    DELETE_SUCCESS

}

export default Status;