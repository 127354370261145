import { Card, Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import Status from "../../enums/Status";
import IApproval from "../../models/approvals/IApproval";
import approvalService from "../../services/approvalService";
import RequestBody from "./RequestBody";
import AlertBody from "../../components/alertBody/AlertBody";

export function Request() {
    const { requestId } = useParams();
    const { t } = useTranslation();

    const [status, setStatus] = useState<Status>(Status.LOADING);
    const [request, setRequest] = useState<IApproval>(null!);

    const id = Number(requestId);

    useEffect(() => fetchRequest(id, setRequest, setStatus), [id]);

    return (
        <Container className="py-3">
            <Row className="justify-content-center">
                <Col>
                    <Card className="shadow">
                        {
                            (status === Status.LOADING && <AlertBody variant="info" message={t("general.loading")} backButtonPath="/requests" />) ||
                            (status === Status.LOAD_ERROR && <AlertBody variant="danger" message={t("approval.errors.loadRequest")} backButtonPath="/requests" />) ||
                            (status === Status.LOAD_SUCCESS && <RequestBody request={request} />)
                        }
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}

function fetchRequest(
    requestId: number,
    setRequest: (article: IApproval) => void,
    setStatus: (status: Status) => void
) {
    approvalService.getRequest(requestId)
        .then(request => {
            setRequest(request);
            setStatus(Status.LOAD_SUCCESS);
        })
        .catch(() => setStatus(Status.LOAD_ERROR));
}