import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Status from "../../enums/Status";
import { Card, Col, Container, Row } from "react-bootstrap";
import ArticleBody from "./ArticleBody";
import IArticle from "../../models/kb/IArticle";
import kbService from "../../services/kbService";
import AlertBody from "../../components/alertBody/AlertBody";

export function Article() {
    const { articleId } = useParams();
    const { t } = useTranslation();

    const [status, setStatus] = useState<Status>(Status.LOADING);
    const [article, setArticle] = useState<IArticle>(null!);

    const id = Number(articleId);

    useEffect(() => fetchArticle(id, setArticle, setStatus), [id]);

    return (
        <Container className="py-3">
            <Row className="justify-content-center">
                <Col>
                    <Card className="shadow">
                        {
                            (status === Status.LOADING && <AlertBody variant="info" message={t("it.kb.article.loading")} backButtonPath="/it/kb" />) ||
                            (status === Status.LOAD_ERROR && <AlertBody variant="danger" message={t("it.kb.article.errors.general")} backButtonPath="/it/kb" />) ||
                            (status === Status.LOAD_SUCCESS && <ArticleBody article={article} />)
                        }
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}

function fetchArticle(
    articleId: number,
    setArticle: (article: IArticle) => void,
    setStatus: (status: Status) => void
) {
    kbService.getArticle(articleId)
        .then(article => {
            setArticle(article);
            setStatus(Status.LOAD_SUCCESS);
        })
        .catch(() => setStatus(Status.LOAD_ERROR));
}