import { ToggleButton } from "react-bootstrap";
import IOption from "./IOption";

interface IOptionProps extends IOption {
    name: string;
    selectedValue?: string;
    onChange: (value: string) => void;
}

const Option = (props: IOptionProps) => {
    const isChecked = props.value === props.selectedValue;
    const variant = isChecked
        ? props.checkedVariant
        : props.uncheckedVariant;

    return (
        <ToggleButton  
            type="radio"
            id={props.id}
            variant={variant}
            checked={isChecked}
            value={props.value}
            disabled={props.disabled}
            onChange={() => props.onChange(props.value)}
            name={props.name}
            key={props.id}>
                {props.text}
        </ToggleButton>
    );
};

export default Option;