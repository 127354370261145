/* eslint-disable jsx-a11y/anchor-has-content */
import { Alert, Card } from "react-bootstrap";
import AuthenticationStatus from "../../enums/AuthenticationStatus";
import { Trans, useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import styles from "./Login.module.scss";

interface IAuthenticateComponentProps {
    setStatus: (status: AuthenticationStatus) => void;
}

const Authenticated = () => {
    const { accounts } = useMsal();
    const { t } = useTranslation();

    return (
        <Alert variant="success">{t('authentication.authorise.success', { name: accounts[0].name })}</Alert>
    );

}

const Unauthenticated = (props: IAuthenticateComponentProps) => {
    const { instance } = useMsal();
    const { t } = useTranslation();

    const handleLogin = async () => {
        try {
            await instance.loginRedirect();
        } catch (_) {
            props.setStatus(AuthenticationStatus.AUTHENTICATION_ERROR);
        }
    };

    const btnImgUrl = `${process.env.PUBLIC_URL}/img/ms-symbollockup_signin_dark.svg`;

    return (
        <div>
            <p>{t('authentication.authorise.description')}</p>
            <img
                src={btnImgUrl}
                onClick={handleLogin}
                className={styles.button}
                alt={t('authentication.authorise.login')} />
        </div>
    );
};

export default function Login() {
    const { instance, accounts, inProgress } = useMsal();
    const { t } = useTranslation();

    const [status, setStatus] = useState<AuthenticationStatus>(AuthenticationStatus.UNAUTHENTICATED);

    useEffect(() => {
        if (["login", "acquireToken", "handleRedirect"].includes(inProgress)) {
            setStatus(AuthenticationStatus.AUTHENTICATION_IN_PROGRESS);
        } else if (accounts.length > 0) {
            setStatus(AuthenticationStatus.AUTHENTICATION_SUCCESSFUL);
        }

        instance.handleRedirectPromise()
            .then((response) => {
                if (response && response.account) {
                    instance.setActiveAccount(response.account);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    }, [instance, accounts, inProgress]);

    return (
        <>
            <Card.Body>
                {
                    {
                        [AuthenticationStatus.AUTHENTICATION_SUCCESSFUL]: <Authenticated />,
                        [AuthenticationStatus.AUTHENTICATION_ERROR]: <Alert variant="danger">{t('authentication.authorise.error')}</Alert>,
                        [AuthenticationStatus.AUTHENTICATION_IN_PROGRESS]: <Alert variant="info">{t('general.pleaseWait')}</Alert>,
                        [AuthenticationStatus.UNAUTHENTICATED]: <Unauthenticated setStatus={setStatus} />
                    }[status]
                }
            </Card.Body>
            {
                status === AuthenticationStatus.UNAUTHENTICATED &&
                <Card.Footer>
                    <Trans i18nKey="authentication.authorise.fm24" components={[<a href="https://www.fm24systems.com" />]} />
                </Card.Footer>
            }
        </>
    );
}