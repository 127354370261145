import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Status from "../../enums/Status";
import IAsset from "../../models/assets/IAsset";
import { Card, Col, Container, Row } from "react-bootstrap";
import AlertBody from "../../components/alertBody/AlertBody";
import LaptopDetails from "./LaptopDetails";
import deviceService from "../../services/deviceService";
import ILAPSDetails from "../../models/assets/ILAPSDetails";

export default function Laptop() {
    const { deviceId } = useParams();
    const { t } = useTranslation();

    const [status, setStatus] = useState<Status>(Status.LOADING);
    const [laptop, setLaptop] = useState<IAsset>(null!);
    const [laps, setLaps] = useState<ILAPSDetails[]>([]);

    const id = Number(deviceId);

    useEffect(() => {
        fetchLaptopDetails(id, setLaptop, setLaps, setStatus);
    }, [id]);

    return (
        <Container className="py-3">
            <Row className="justify-content-center">
                <Col>
                    <Card className="shadow">
                        {
                            (status === Status.LOADING && <AlertBody variant="info" message={t("laps.loading.single")} backButtonPath="/laps" />) ||
                            (status === Status.LOAD_ERROR && <AlertBody variant="danger" message={t("laps.errors.laptop")} backButtonPath="/laps" />) ||
                            (status === Status.LOAD_SUCCESS && <LaptopDetails laptop={laptop} laps={laps} />)
                        }
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}

async function fetchLaptopDetails(
    deviceId: number,
    setLaptop: (asset: IAsset) => void,
    setLAPS: (laps: ILAPSDetails[]) => void,
    setStatus: (status: Status) => void
) {
    try {
        const device = await deviceService.getDevice(deviceId);
        device.laptopName = await deviceService.getDeviceLaptopName(deviceId);
        setLaptop(device);

        const laps = await deviceService.getLAPSDetails(deviceId);
        setLAPS(laps);

        setStatus(Status.LOAD_SUCCESS);
    } catch (error) {
        setStatus(Status.LOAD_ERROR);
    }
}