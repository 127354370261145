import { Badge, Col, Container, ListGroup, Row } from "react-bootstrap";
import IApproval from "../../models/approvals/IApproval";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import RequestOutcome from "../../enums/RequestOutcome";
import { ReactNode, useEffect, useState } from "react";
import userService from "../../services/userService";
import styles from "./Request.module.scss";
import { useNavigate } from "react-router-dom";

const OutcomeColour = (outcome: RequestOutcome) => {
    switch (outcome) {
        case RequestOutcome.ACCEPTED:
            return "success";
        case RequestOutcome.REJECTED:
            return "danger";
        default:
            return "light";
    }
};

const CellContent = ({ children } : { children: ReactNode }) => (
    <div className={styles.flex}>
        <div className={styles.block}>
            {children}
        </div>
    </div>
);

export default function Request({ request } : { request: IApproval }) {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [photo, setPhoto] = useState<string>(null!);

    useEffect(() => {
        getPhoto(request.requester.email, setPhoto);
    }, [request.requester.email]);

    const created = dayjs(request.ticket.created).format("DD/MM/YYYY");
    const outcome = t(`approval.outcomes.${request.outcome}`),
        outcomeColour = OutcomeColour(request.outcome);

    const onClick = () => navigate(`/request/${request.id}`);

    const codeCodeColSpan = request.outcome === RequestOutcome.NOT_REVIEWED ? 3 : 2;

    return (
        <ListGroup.Item action key={request.id} onClick={onClick}>
            <Container fluid>
                <Row className="align-items-center">
                    <Col xs={12} lg={4}>
                        <CellContent>
                            <span className="d-block fw-bold">{request.requestNumber}</span>
                            <span className="d-block">{request.ticket.title}</span>
                        </CellContent>
                    </Col>
                    <Col xs={12} lg={2}>{created}</Col>
                    {
                        request.outcome !== RequestOutcome.NOT_REVIEWED &&
                        <Col xs={12} lg={1}>
                            <Badge pill bg={outcomeColour}>{outcome}</Badge>
                        </Col>
                    }
                    <Col xs={12} lg={3}>
                        {
                            photo &&
                            <img src={`data:image/any;base64,${photo}`} alt={request.requester.name} className={styles.avatar} />
                        }
                        <span>{request.requester.name}</span>
                    </Col>
                    <Col lg={codeCodeColSpan} className="d-none d-lg-block">{request.costCode}</Col>
                </Row>
            </Container>
        </ListGroup.Item>
    );
}

async function getPhoto(email: string, setPhoto: (photo: string) => void) {
    try {
        const photo = await userService.getPhoto(email);
        setPhoto(photo ?? null!);
    } catch {
        setPhoto(null!);
    }
}
