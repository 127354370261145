import { useTranslation } from "react-i18next";
import ButtonRadio from "../../../components/formFields/btnRadio/ButtonRadio";
import RequestOutcome from "../../../enums/RequestOutcome";
import IOption from "../../../components/formFields/btnRadio/IOption";

interface IDecisionButtonsProps {
    disabled: boolean;
    decision: RequestOutcome;
    setDecision: (decision: RequestOutcome) => void;
}

export default function DecisionButtons(props: IDecisionButtonsProps) {
    const { t } = useTranslation();
    
    const options: IOption[] = [
        {
            uncheckedVariant: "outline-success",
            checkedVariant: "success",
            value: RequestOutcome.ACCEPTED.toString(),
            id: "approve",
            text: t("approval.actions.approve"),
            disabled: props.disabled
        },
        {
            uncheckedVariant: "outline-danger",
            checkedVariant: "danger",
            value: RequestOutcome.REJECTED.toString(),
            id: "reject",
            text: t("approval.actions.reject"),
            disabled: props.disabled
        }
    ];

    return (
        <ButtonRadio
            name="outcome"
            options={options}
            disabled={props.disabled}
            onChange={(value) => props.setDecision(parseInt(value))}
            selectedValue={props.decision.toString()}
            />
    );
}