import FormFieldValidation from "../../enums/FormFieldValidation";
import IOnboarding from "../../models/hr/onboarding/IOnboarding";
import IOnboardingValidation from "./IOnboardingValidation";

export default function validate(data: IOnboarding, setValidation: (validation: IOnboardingValidation) => void) {
    const validateField = (condition: boolean, value: any) => 
        condition
            ? value && value.trim().length > 0
                ? FormFieldValidation.VALID
                : FormFieldValidation.INVALID
            : FormFieldValidation.VALID;

    const validation: IOnboardingValidation = {
        selectedLaptopTypeId: data.isLaptopRequired
            ? data.selectedLaptopTypeId !== undefined
                ? FormFieldValidation.VALID
                : FormFieldValidation.INVALID
            : FormFieldValidation.VALID,
        deliveryAddress: validateField(data.isLaptopRequired || data.isPhoneRequired, data.deliveryAddress),
        costCode: validateField(true, data.costCode),
        conceptAccessRequired: validateField(data.isConceptAccessRequired, data.conceptAccessRequired),
        prismAccessRequired: validateField(data.isPrismAccessRequired, data.prismAccessRequired)
    };

    setValidation(validation);

    return Object.values(validation).every(v => v === FormFieldValidation.VALID);
}