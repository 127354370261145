import Option from "./Option";
import { ButtonGroup } from "react-bootstrap";
import IButtonRadioProps from "./IButtonRadioProps";

export default function ButtonRadio(props: IButtonRadioProps) {
    return (
        <ButtonGroup className="mb-2">
            {
                props.options.map((option) => (
                    <Option {...option}
                            name={props.name}
                            selectedValue={props.selectedValue}
                            onChange={props.onChange}
                            disabled={props.disabled}
                            />
                ))
            }
        </ButtonGroup>
    );
}