import FormFieldValidation from "../../../enums/FormFieldValidation";
import ITicket from "../../../models/helpdesk/it/ITicket";
import ITicketValidation from "./details/ITicketValidation";

export default function validate(ticket: ITicket, setValidation: (validation: ITicketValidation) => void) {
    const validation: ITicketValidation = {
        title: ticket.title.length > 0 
            ? FormFieldValidation.VALID
            : FormFieldValidation.INVALID,
        description: ticket.description.length > 0
            ? FormFieldValidation.VALID
            : FormFieldValidation.INVALID,
        questions: ticket.questions.every(q => q.answer && q.answer.trim().length > 0)
            ? FormFieldValidation.VALID
            : FormFieldValidation.INVALID
    };

    setValidation(validation);

    return Object.values(validation).every(v => v === FormFieldValidation.VALID);
}