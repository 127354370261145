import { useEffect, useRef } from "react";
import styles from './RichTextField.module.scss';
import Quill from 'quill';
import "quill/dist/quill.snow.css";
import { modules, formats } from "./QuillConfig";
import IRichTextFieldProps from "./IRichTextFieldProps";
import { useTranslation } from "react-i18next";
import { IsValid } from "../Validation";

export default function RichTextField(props: IRichTextFieldProps) {
    const { t } = useTranslation();

    const quillRef = useRef<Quill | null>(null);
    const quillContainerRef = useRef<HTMLDivElement | null>(null);

    const validationMessage = props.validationMessage || t("general.provideValue");
    const isValid = IsValid(props.validation);

    useEffect(() => {
        if (quillContainerRef.current && !quillRef.current) {
            quillRef.current = new Quill(quillContainerRef.current, {
                readOnly: props.disabled,
                theme: 'snow',
                modules,
                formats,
                placeholder: props.placeholder
            });
            
            quillRef.current.on('editor-change', (eventName: string) => {
                if (eventName === 'selection-change' && quillRef.current) {
                    props.onChange(quillRef.current.root.innerHTML);
                }
            });
        } else {
            quillRef.current?.enable(!props.disabled);
        }
    }, [props]);
  
    return (
        <div className="d-block mb-3">
            {
                props.label &&
                <label className={styles.label}>{props.label}</label>
            }
            <div ref={quillContainerRef} className={styles.editor} />
            {
                isValid === false &&
                <div className="text-danger">
                    {validationMessage}
                </div>
            }
        </div>
    );
}