import { MessageAction } from "../enums/MessageAction";
import IMessage from "../models/helpdesk/comms/IMessage";
import networkService from "./networkService";

class CommunicationService {

    /**
     * @description Fetches all messages for a ticket
     * @param {string} ticketId The ID of the ticket to fetch messages for
     */
    async getMessages(ticketId: number) : Promise<IMessage[]> {
        const result = await networkService.get<IMessage[]>(`helpdesk/ticket/${ticketId}/communication`);

        if (result.isSuccessful) {
            return result.status === 200
                ? result.data as IMessage[]
                : [];
        }

        throw result.data;
    }

    /**
     * @description Creates & sends a message against a ticket
     * @param {IMessage} message The message to be sent
     */
    async createMessage(message: IMessage, action: MessageAction = MessageAction.SEND_ONLY) : Promise<boolean> {
        const result = await networkService.put<IMessage, number>(`helpdesk/ticket/${message.ticketId}/communication?action=${action}`, message);
        return result.isSuccessful;
    }

}

const communicationService = new CommunicationService();
export default communicationService;