import { FloatingLabel, Form } from "react-bootstrap";
import ILargeFloatingTextFieldProps from "./ILargeFloatingTextFieldProps";
import { v4 as uuid } from "uuid";
import { IsValid, ValidationCssClass } from "../Validation";
import { useTranslation } from "react-i18next";

export default function LargeFloatingTextField(props: ILargeFloatingTextFieldProps) {
    const { t } = useTranslation();

    const validationMessage = props.validationMessage || t("general.provideValue");
    
    let validationCssClass = ValidationCssClass(props.validation);
    let isValid = IsValid(props.validation);

    return (
        <FloatingLabel  controlId={`txt-${uuid()}`}
                        label={props.label}
                        className="mb-3">
            <Form.Control   as="textarea"
                            placeholder={props.placeholder}
                            className={validationCssClass}
                            style={{ height: `${props.height}px` }}
                            value={props.value}
                            isValid={isValid}
                            disabled={props.disabled}
                            onChange={(e) => props.onChange(e.target.value)}
                            />
            {
                isValid === false &&
                <div className="text-danger">
                    {validationMessage}
                </div>
            }
        </FloatingLabel>
      );
}