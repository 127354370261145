import { Alert, Button, Col, Form, Row } from "react-bootstrap";
import FloatingTextField from "../../../components/formFields/floatingText/FloatingTextField";
import LargeFloatingTextField from "../../../components/formFields/largeFloatingText/LargeFloatingTextField";
import { useTranslation } from "react-i18next";
import RequestOutcome from "../../../enums/RequestOutcome";
import { FormEvent, useState } from "react";
import Status from "../../../enums/Status";
import FormFieldValidation from "../../../enums/FormFieldValidation";
import DecisionButtons from "./DecisionButtons";
import approvalService from "../../../services/approvalService";

export default function ApprovalForm({ requestId, isCostCodeRequired } : { requestId: number, isCostCodeRequired: boolean }) {
    const { t } = useTranslation();

    const [status, setStatus] = useState<Status>(Status.NEUTRAL);
    const [outcome, setOutcome] = useState<RequestOutcome>(RequestOutcome.NOT_REVIEWED);
    const [costCode, setCostCode] = useState<string>("");
    const [costCodeValidation, setCostCodeValidation] = useState<FormFieldValidation>(FormFieldValidation.NOT_VALIDATED);
    const [reason, setReason] = useState<string>("");
    const [reasonValidation, setReasonValidation] = useState<FormFieldValidation>(FormFieldValidation.NOT_VALIDATED);

    const onSubmitted = (ev: FormEvent) => {
        ev.preventDefault();

        setStatus(Status.NEUTRAL);

        const isValid = validate(outcome, costCode, isCostCodeRequired, reason, setCostCodeValidation, setReasonValidation);
        if (!isValid) {
            return;
        }

        setStatus(Status.SAVING);

        approvalService.postDecision({ requestId, outcome, reason, costCode })
            .then(success => {
                setStatus(success ? Status.SAVE_SUCCESS : Status.SAVE_ERROR);
                if (success) {
                    setTimeout(() => window.location.reload(), 1_000);
                }
            })
            .catch(() => setStatus(Status.SAVE_ERROR));
    };

    const isDisabled = status === Status.LOADING || status === Status.SAVING || status === Status.SAVE_SUCCESS;

    return (
        <Row>
            <Col>
                <h2 className="h3">{t("approval.form.title")}</h2>
                <Form onSubmit={onSubmitted}>
                    <DecisionButtons disabled={isDisabled} decision={outcome} setDecision={setOutcome} />

                    {
                        outcome === RequestOutcome.ACCEPTED &&
                        <FloatingTextField
                            label={t("approval.form.fields.costCode.label")}
                            placeholder={t("approval.form.fields.costCode.placeholder")}
                            disabled={isDisabled}
                            onChange={value => setCostCode(value)}
                            validation={costCodeValidation}
                            type="text"
                            value={costCode}
                            />
                    }

                    {
                        outcome !== RequestOutcome.NOT_REVIEWED &&
                        <LargeFloatingTextField
                            label={t("approval.form.fields.reason.label")}
                            placeholder={t("approval.form.fields.reason.placeholder")}
                            disabled={isDisabled}
                            onChange={value => setReason(value)}
                            validation={reasonValidation}
                            type="textarea"
                            value={reason}
                            height={150}
                            />
                    }

                    {
                        status === Status.SAVING &&
                        <Alert variant="info">
                            {
                                outcome === RequestOutcome.ACCEPTED
                                    ? t("approval.approving")
                                    : t("approval.rejecting")
                            }
                        </Alert>
                    }

                    {
                        status === Status.SAVE_SUCCESS &&
                        <Alert variant="success">
                           {
                                outcome === RequestOutcome.ACCEPTED
                                    ? t("approval.approved")
                                    : t("approval.rejected")
                           }
                        </Alert>
                    }

                    {
                        status === Status.SAVE_ERROR &&
                        <Alert variant="danger">
                            {t("approval.errors.general")}
                        </Alert>
                    }

                    {
                        outcome !== RequestOutcome.NOT_REVIEWED &&
                        <div className="text-center">
                            <Button type="submit" variant="primary" disabled={isDisabled}>
                                {t("general.submit")}
                            </Button>
                        </div>
                    }
                </Form>
            </Col>
        </Row>
    );
}

function validate (
    outcome: RequestOutcome,
    costCode: string,
    isCostCodeRequired: boolean,
    reason: string,
    setCostCodeValidation: (validation: FormFieldValidation) => void,
    setReasonValidation: (validation: FormFieldValidation) => void
) {
    let reasonValidation = outcome === RequestOutcome.ACCEPTED || reason.trim().length > 0
        ? FormFieldValidation.VALID
        : FormFieldValidation.INVALID;

    let costCodeValidation = costCode.trim().length > 0 || !isCostCodeRequired || outcome === RequestOutcome.REJECTED
        ? FormFieldValidation.VALID
        : FormFieldValidation.INVALID;

    setReasonValidation(reasonValidation);
    setCostCodeValidation(costCodeValidation);

    return reasonValidation === FormFieldValidation.VALID && costCodeValidation === FormFieldValidation.VALID;
}