import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Alert, Col, Row } from "react-bootstrap";
import Status from "../../../../enums/Status";
import itHelpdeskService from "../../../../services/itHelpdeskService";
import ITopic from "../../../../models/helpdesk/it/ITopic";
import { TopicTiles } from "./tiles/TopicTiles";
import ITopicSectionProps from "./ITopicSectionProps";

export const TopicSection = (props: ITopicSectionProps) => {
    const { t } = useTranslation();

    const [topics, setTopics] = useState<ITopic[]>([]);
    const [topicId, setTopicId] = useState<string>(null!);
    const [status, setStatus] = useState<Status>(Status.LOADING);
    const [showOutOfScope, setShowOutOfScope] = useState<boolean>(false);

    useEffect(() => {
        itHelpdeskService.getTopics(props.platform.id)
            .then((data) => {
                setTopics(data);
                setStatus(Status.LOAD_SUCCESS);
            })
            .catch(() => {
                setStatus(Status.LOAD_ERROR);
            });
    }, [props.platform.id]);

    const onTopicClick = (topicId: string) => {
        setTopicId(topicId);

        // Check if the topic is out of scope
        const topic = topics.find((topic) => topic.id === topicId)!;
        if (!topic.inScope) {
            setShowOutOfScope(true);
            return;
        }

        // Check if the topic has children
        setStatus(Status.LOADING);

        itHelpdeskService.getTopic(topicId)
            .then((topic) => {
                if (topic.children.length === 0) {
                    props.onClick(topic);
                    return;
                }
                
                setTopics(topic.children);
                setStatus(Status.LOAD_SUCCESS);
            })
            .catch(() => setStatus(Status.LOAD_ERROR));
    };

    const topic = topics.find((topic) => topic.id === topicId);
    const title = topic ? topic.name : t("it.new.pre-submission.topics.title");

    if (showOutOfScope) {
        return (
            <Row>
                <Col>
                    <Alert variant="dark" className="mb-0">
                        <p className="mb-2">
                            {t("it.new.outOfScope", { topic: topic!.name })}
                        </p>
                        <p className="mb-1">
                            {topic!.outOfScopeMessage}
                        </p>
                    </Alert>
                </Col>
            </Row>
        );
    }

    return (
        <Row>
            <Col>
                <Row>
                    <Col>
                        <h3>{title}</h3>
                        {topic && <p className="text-muted">{topic.subtitle}</p>}
                    </Col>
                </Row>
                {
                    status === Status.LOADING &&
                    <Alert variant="info">
                        {t("general.pleaseWait")}
                    </Alert>
                }
                {
                    status === Status.LOAD_SUCCESS &&
                    topics.length === 0 &&
                    <Alert variant="info">
                        {t("it.new.pre-submission.errors.noTopics")}
                    </Alert>
                }
                {
                    status === Status.LOAD_ERROR &&
                    <Alert variant="danger">
                        {t("it.new.pre-submission.errors.loadTopics")}
                    </Alert>
                }
                {
                    status === Status.LOAD_SUCCESS &&
                    <TopicTiles topics={topics} onClick={onTopicClick} />
                }
            </Col>
        </Row>
    );
};