enum FormFieldValidation {

    /**
     * @description The field has not been validated
     */
    NOT_VALIDATED = 'NOT_VALIDATED',

    /**
     * @description The field is valid
     */
    VALID = 'VALID',

    /**
     * @description The field is invalid
     */
    INVALID = 'INVALID',

    /**
     * @description The field is an email address and not a valid format
     */
    INVALID_EMAIL = 'INVALID_EMAIL'
}

export default FormFieldValidation;