import { Container, Row, Col, Card } from "react-bootstrap";
import NewTicketForm from "./NewTicketForm";
import { useTranslation } from "react-i18next";
import BackButton from "../../../components/backButton/BackButton";

export function NewTicket() {
    const { t } = useTranslation();
    
    return (
        <Container className="py-3">
            <Row className="justify-content-center">
                <Col>
                    <Card className="shadow">
                        <Card.Body>
                            <Card.Title className="d-flex align-items-center mb-3">
                                <BackButton path="/hr" />
                                <span>{t("ticket.form.title.hr")}</span>
                            </Card.Title>
                            <Card.Text as="div">
                                <NewTicketForm />
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}