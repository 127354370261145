import { useEffect, useState } from 'react';
import { fileTypeFromBuffer } from 'file-type';
import styles from './PreviewItem.module.scss';
import Status from '../../../../enums/Status';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Icon } from '../../../icon/Icon';
import IPreviewItemProps from './IPreviewItemProps';
import { useTranslation } from 'react-i18next';

const formatFileSize = (size: number) => {
    if (size < 1024) return `${size} B`;
    if (size < 1024 * 1024) return `${(size / 1024).toFixed(2)} KB`;
    return `${(size / (1024 * 1024)).toFixed(2)} MB`;
};

const getFileType = async (file: File) : Promise<string> => {
    const buffer = await file.arrayBuffer();
    const fileType = await fileTypeFromBuffer(new Uint8Array(buffer));
    return fileType
        ? fileType.ext.toUpperCase()
        : '';
};

const getPreview = async (file: File) : Promise<string> => {
    if (!file.type.startsWith("image/") || file.size > 2_097_152) {
        return null!;
    }

    const buffer = await file.arrayBuffer();
    const blob = new Blob([buffer], { type: file.type });
    return URL.createObjectURL(blob);
}

export default function PreviewItem(props: IPreviewItemProps) {
    const { t } = useTranslation();

    const [status, setStatus] = useState<Status>(Status.LOADING);
    const [fileType, setFileType] = useState<string>('');
    const [thumbnail, setThumbnail] = useState<string>('');

    const file = props.file;
    
    useEffect(() => {
        setStatus(Status.LOADING);
        preparePreview(file, setFileType, setThumbnail)
            .then(() => setStatus(Status.LOAD_SUCCESS))
            .catch(() => setStatus(Status.LOAD_ERROR));
    }, [file]);

    const onRemove = () => props.onRemove();

    return (
        <li className={styles.previewItem}>
            <div className="d-flex align-items-center">
                <div className={`${styles.imgPreview} me-4`}>
                    {thumbnail && <img src={thumbnail} alt={file.name} />}
                </div>
                <div className="d-block">
                    <div>{file.name}</div>
                    <div className='small'>
                        <span className='d-inline-block text-muted me-2'>{formatFileSize(file.size)}</span>
                        {status === Status.LOAD_SUCCESS && fileType && <span>{fileType}</span>}
                    </div>
                </div>
                <div className={`${styles.remove} ms-auto`}>
                    <OverlayTrigger overlay={<Tooltip id="tooltip-remove">{t("general.remove")}</Tooltip>} placement='left'>
                        <Button variant='danger' onClick={onRemove}>
                            <Icon iconName='TrashFill' />
                        </Button>
                    </OverlayTrigger>
                </div>
            </div>
        </li>
    );
}

async function preparePreview(
    file: File,
    setFileType: ($type: string) => void,
    setThumbnail: ($thumbnail: string) => void)
{
    const fileType = await getFileType(file);
    setFileType(fileType);
    
    const preview = await getPreview(file);
    setThumbnail(preview);
}