import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import IPlatform from "../../../../models/helpdesk/it/IPlatform";
import Status from "../../../../enums/Status";
import itHelpdeskService from "../../../../services/itHelpdeskService";
import { Alert, Col, Row } from "react-bootstrap";
import { PlatformTiles } from "./tiles/PlatformTiles";
import IPlatformSectionProps from "./IPlatformSectionProps";

export const PlatformSection = (props: IPlatformSectionProps) => {
    const { t } = useTranslation();

    const [platforms, setPlatforms] = useState<IPlatform[]>([]);
    const [status, setStatus] = useState<Status>(Status.LOADING);

    useEffect(() => {
        itHelpdeskService.getPlatforms()
            .then((data) => {
                setPlatforms(data);
                setStatus(Status.LOAD_SUCCESS);
            })
            .catch(() => {
                setStatus(Status.LOAD_ERROR);
            });
    }, []);

    return (
        <Row>
            <Col>
                <Row>
                    <Col>
                        <h3>{t("it.new.pre-submission.platforms.title")}</h3>
                    </Col>
                </Row>
                {
                    status === Status.LOADING &&
                    <Alert variant="info">
                        {t("it.new.pre-submission.platforms.loading")}
                    </Alert>
                }
                {
                    status === Status.LOAD_SUCCESS &&
                    platforms.length === 0 &&
                    <Alert variant="info">
                        {t("it.new.pre-submission.errors.noPlatforms")}
                    </Alert>
                }
                {
                    status === Status.LOAD_ERROR &&
                    <Alert variant="danger">
                        {t("it.new.pre-submission.errors.loadPlatforms")}
                    </Alert>
                }
                {
                    status === Status.LOAD_SUCCESS &&
                    <PlatformTiles platforms={platforms} onClick={props.onClick} />
                }
            </Col>
        </Row>
    );
};