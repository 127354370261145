import { Alert, Button, ButtonGroup, Card, Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import BackButton from "../../components/backButton/BackButton";
import { useEffect, useState } from "react";
import Status from "../../enums/Status";
import IOnboarding from "../../models/hr/onboarding/IOnboarding";
import OnboardingFormBody from "./OnboardingFormBody";
import IOnboardingValidation from "./IOnboardingValidation";
import FormFieldValidation from "../../enums/FormFieldValidation";
import onboardingService from "../../services/onboardingService";
import ILaptopType from "../../models/hr/onboarding/ILaptopType";
import AlertBody from "../../components/alertBody/AlertBody";
import EmployeeDetails from "./EmployeeDetails";
import validate from "./Validate";

export default function OnboardingForm() {
    const { id } = useParams();
    const { t } = useTranslation();

    const [data, setData] = useState<IOnboarding>();
    const [status, setStatus] = useState<Status>(Status.LOADING);
    const [laptops, setLaptops] = useState<ILaptopType[]>([]);
    const [validation, setValidation] = useState<IOnboardingValidation>({
        selectedLaptopTypeId: FormFieldValidation.NOT_VALIDATED,
        deliveryAddress: FormFieldValidation.NOT_VALIDATED,
        costCode: FormFieldValidation.NOT_VALIDATED,
        conceptAccessRequired: FormFieldValidation.NOT_VALIDATED,
        prismAccessRequired: FormFieldValidation.NOT_VALIDATED
    });

    useEffect(() => {
        const onboardingId = Number(id);
        getData(onboardingId, setData, setLaptops)
            .then(() => setStatus(Status.LOAD_SUCCESS))
            .catch(() => setStatus(Status.LOAD_ERROR));
    }, [id]);

    const isReadOnly = [Status.LOADING, Status.LOAD_ERROR, Status.SAVING, Status.SAVE_SUCCESS].includes(status);

    const handleInputChange = (prop: keyof IOnboarding, value: any) => {
        console.log(prop, value);

        setData(employee => ({ ...employee!, [prop]: value }));
        if (Object.keys(validation).includes(prop)) {
            setValidation(validation => ({ ...validation, [prop]: FormFieldValidation.NOT_VALIDATED }));
        }
    }

    const handleSave = () => {
        setStatus(Status.SAVING);
        onboardingService.saveInstance(data!)
            .then(() => {
                setStatus(Status.SAVE_SUCCESS);
                setTimeout(() => setStatus(Status.LOAD_SUCCESS), 2_000);
            })
            .catch(() => setStatus(Status.SAVE_ERROR));
    }

    const handleSubmit = () => {
        const isValid = validate(data!, setValidation);
        if (!isValid) {
            return;
        }
        
        setStatus(Status.SAVING);
        onboardingService.submitInstance(data!)
            .then(() => setStatus(Status.SAVE_SUCCESS))
            .catch(() => setStatus(Status.SAVE_ERROR));
    };

    if ([Status.LOADING, Status.LOAD_ERROR].includes(status)) {
        return (
            <Container className="py-3">
                <Row className="justify-content-center">
                    <Col>
                        <Card className="shadow">
                            {
                                (status === Status.LOADING && <AlertBody variant="info" message={t("general.loading")} />) ||
                                (status === Status.LOAD_ERROR && <AlertBody variant="danger" message={t("onboarding.errors.load")} />)
                            }
                        </Card>
                    </Col>
                </Row>
            </Container>
        )
    }

    return (
        <Container className="py-3">
            <Row className="justify-content-center">
                <Col>
                    <Card className="shadow">
                        <Card.Body>
                            <Card.Title className="d-flex align-items-center mb-3">
                                <BackButton path="/it" />
                                <span>{t("onboarding.title")}</span>
                            </Card.Title>
                            <Card.Text as="form" method="POST" onSubmit={() => {}}>
                                <EmployeeDetails onboarding={data!} />
                                <OnboardingFormBody
                                    handleInputChange={handleInputChange}
                                    handleSubmit={() => {}}
                                    isReadOnly={isReadOnly}
                                    status={status}
                                    laptops={laptops}
                                    onboarding={data!}
                                    validation={validation}                                    
                                    />
                                <Row>
                                    <Col>
                                        {
                                            (status === Status.SAVE_SUCCESS && <Alert variant="success">{t("general.draftSaved")}</Alert>) ||
                                            (status === Status.SAVE_ERROR && <Alert variant="danger">{t("onboarding.errors.save")}</Alert>)
                                        }
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="text-center">
                                        <ButtonGroup size="lg">
                                            <Button variant="info" type="button" className="px-2" onClick={handleSave}>
                                                {t("general.saveDraft")}
                                            </Button>
                                            <Button variant="primary" type="button" className="fw-bold px-5" onClick={handleSubmit}>
                                                {t("general.submit")}
                                            </Button>
                                        </ButtonGroup>
                                    </Col>
                                </Row>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}

async function getData(
    id: number,
    setInstanceData: (employee: IOnboarding) => void,
    setLaptops: (laptops: ILaptopType[]) => void
) {
    const laptops = await onboardingService.getLaptopTypes();
    setLaptops(laptops);

    const employee = await getOnboardingElement(id);
    setInstanceData(employee);
}

async function getOnboardingElement (id: number): Promise<IOnboarding> {
    const employee = await onboardingService.getInstance(id);
    return employee;
}