import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import IRatingButtonProps from "./IRatingButtonProps";
import { Icon } from "../../../../components/icon/Icon";
import { FeedbackType } from "../../../../enums/FeedbackType";
import { useTranslation } from "react-i18next";

const btnVariant = (active: boolean, feedback: FeedbackType) => {
    let variant = "";
    
    switch (feedback) {
        case FeedbackType.POSITIVE:
            variant = "success";
            break;
        case FeedbackType.NEUTRAL:
            variant = "warning";
            break;
        case FeedbackType.NEGATIVE:
            variant = "danger";
            break;
        default:
            variant = "secondary";
            break;
    }

    if (!active) {
        variant = `outline-${variant}`;
    }

    return variant;
}

export const FeedbackButton = (props: IRatingButtonProps) => {
    const { t } = useTranslation();
    
    const handleFeedback = () => props.handleFeedback(props.feedback);

    const variant = btnVariant(props.active, props.feedback);
    const tooltipText = t(`feedback.feedbackTooltips.${props.feedback}`);

    return (
        <OverlayTrigger overlay={<Tooltip>{tooltipText}</Tooltip>}>
            <Button variant={variant}
                    onClick={handleFeedback}
                    disabled={props.disabled}
                    className="rounded-circle py-3 px-4"
                    size="lg"
                    >
                <span className="display-6">
                    <Icon iconName={props.emoji} />
                </span>
            </Button>
        </OverlayTrigger>
    );
};