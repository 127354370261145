import { ListGroup } from "react-bootstrap";
import IAsset from "../../models/assets/IAsset";
import { Link } from "react-router-dom";

export default function Laptop(props: { laptop: IAsset }) {
    const { laptop } = props;

    return (
        <ListGroup.Item key={laptop.id} variant="action" as={Link} to={`/laps/${laptop.id}`} className="py-0">
            <span className="fw-bold">{laptop.laptopName} ({laptop.name})</span>
            <br />
            <span>{laptop.serviceTag}</span>
        </ListGroup.Item>
    );
}