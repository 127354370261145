import { useMsal } from "@azure/msal-react";
import Status from "../../../enums/Status";
import { useEffect, useState } from "react";
import { AccountInfo, IPublicClientApplication } from "@azure/msal-browser";
import { Dropdown } from "react-bootstrap";
import IUser from "../../../models/user/IUser";
import styles from "./Account.module.scss";
import { useTranslation } from "react-i18next";
import accountService from "../../../services/accountService";

const AccountDetails = ({ account, photo }: { account: IUser, photo: string }) => (
    <div className="d-flex align-items-center">
        {
            photo &&
            <img
                alt={account.displayName}
                src={`data:image/*;base64,${photo}`}
                className={`rounded-circle me-2 ${styles.photo}`}
                />
        }
        <div className="d-block text-start small">
            <span className="d-block fw-bold">{account.displayName}</span>
            <span className="d-block">{account.mail}</span>
        </div>
    </div>
);

export default function Account() {
    const { t } = useTranslation();
    const { instance, accounts } = useMsal();

    const [status, setStatus] = useState<Status>(Status.LOADING);
    const [account, setAccount] = useState<IUser>();
    const [photo, setPhoto] = useState<string>("");

    useEffect(() => {
        instance.initialize()
            .then(async () => {
                const account = await getAccount(instance, accounts[0]);    
                setAccount(account);
                
                try {
                    const photo = await getProfilePicture(instance, accounts[0]);
                    setPhoto(photo);
                } catch (error) {
                    setPhoto("");
                }
            })
            .then(() => setStatus(Status.LOAD_SUCCESS))
            .catch(() => setStatus(Status.LOAD_ERROR));
    }, [accounts, instance]);

    return (
        <Dropdown>
            <Dropdown.Toggle variant="outline-light" className={styles.button}>
                {status !== Status.LOAD_SUCCESS ? 
                    t("account.account") :
                    <AccountDetails account={account!} photo={photo} />
                }
            </Dropdown.Toggle>

            <Dropdown.Menu>
                <Dropdown.Item onClick={() => instance.logout()}>{t("account.logout")}</Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    );
}

async function getAccount(instance: IPublicClientApplication, account: AccountInfo) {
    return await accountService.getAccount();
}

async function getProfilePicture(instance: IPublicClientApplication, account: AccountInfo) {
    return await accountService.getProfilePicture();
}