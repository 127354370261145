import { Accordion, Alert, Button, Card, Col, Container, Row } from "react-bootstrap";
import ITicket from "../../../../models/helpdesk/it/ITicket";
import { useTranslation } from "react-i18next";
import BackButton from "../../../../components/backButton/BackButton";
import { useState } from "react";
import LargeFloatingTextField from "../../../../components/formFields/largeFloatingText/LargeFloatingTextField";
import Status from "../../../../enums/Status";
import FormFieldValidation from "../../../../enums/FormFieldValidation";
import itHelpdeskService from "../../../../services/itHelpdeskService";
import { Link } from "react-router-dom";

const CardTitle = ({ ticket } : { ticket: ITicket }) => (
    <Card.Title className="d-flex align-items-center justify-content-between mb-3">
        <div className="d-flex align-items-center">
            <BackButton path="/it" />
            <Link to={`/it/ticket/${ticket.id}`} className="text-decoration-none">
                {ticket.ticketNumber}
            </Link>
        </div>
    </Card.Title>
);

export default function EscalationBody({ ticket } : { ticket: ITicket }) {
    const { t } = useTranslation();

    const [status, setStatus] = useState<Status>(Status.NEUTRAL);
    const [message, setMessage] = useState<string>("");

    const isReadOnly = [Status.SAVING, Status.SAVE_SUCCESS].includes(status);

    const placeholderText = t("feedback.feedbackPlaceholders.escalate");

    const handleSubmit = () => {
        setStatus(Status.SAVING);
        submitEscalation(ticket.id, message)
            .then(() => setStatus(Status.SAVE_SUCCESS))
            .catch(() => setStatus(Status.SAVE_ERROR));
    };

    if (status === Status.SAVE_SUCCESS) {
        return (
            <Card.Body>
                <CardTitle ticket={ticket} />
                <Card.Text as ="div">
                    <Container fluid>
                        <Row>
                            <Col>
                                <Alert variant="success" className="mb-0">
                                    {t("feedback.success")}
                                </Alert>
                            </Col>
                        </Row>
                    </Container>
                </Card.Text>
            </Card.Body>
        );
    }

    return (
        <Card.Body>
            <CardTitle ticket={ticket} />
            <Card.Text as="div">
                <Container fluid>
                    <Row>
                        <Col>
                            <h1 className="h2">{ticket.title}</h1>
                            <p className="text-muted">{t("feedback.actionedBy", { name: ticket.assignee!.name })}</p>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Accordion className="w-100">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>{t("feedback.caseDescription")}</Accordion.Header>
                                <Accordion.Body dangerouslySetInnerHTML={{ __html: ticket.description }} />
                            </Accordion.Item>
                        </Accordion>
                    </Row>
                    <Row>
                        <Col>
                            <h3>
                                {t("feedback.escalate")}
                            </h3>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <LargeFloatingTextField
                                label={placeholderText}
                                placeholder={placeholderText}
                                disabled={isReadOnly}
                                height={200}
                                type="text"
                                onChange={(value: string) => setMessage(value)}
                                value={message}
                                validation={FormFieldValidation.NOT_VALIDATED}
                                />
                            {
                                status === Status.SAVING &&
                                <Alert variant="info">
                                    {t("general.pleaseWait")}
                                </Alert>
                            }
                            {
                                status === Status.SAVE_ERROR &&
                                <Alert variant="danger">
                                    {t("feedback.errors.general")}
                                </Alert>
                            }
                            <Button variant="primary" onClick={handleSubmit}>
                                {t("general.submit")}
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </Card.Text>
        </Card.Body>
    );
}

async function submitEscalation(ticketId: number, message: string): Promise<boolean> {
    const result = await itHelpdeskService.submitEscalation(ticketId, message);
    return result;
}