import { ReactNode, useEffect, useState } from "react";
import { Col, Container, ListGroup, Row } from "react-bootstrap";
import IArticle from "../../models/kb/IArticle";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import styles from "./Article.module.scss";
import userService from "../../services/userService";

interface ITicketProps {
    article: IArticle;
}

const CellContent = ({ children } : { children: ReactNode }) => (
    <div className={styles.flex}>
        <div className={styles.block}>
            {children}
        </div>
    </div>
);

export default function Article(props: ITicketProps) {
    const { article } = props;
    const { t } = useTranslation();

    const [photo, setPhoto] = useState<string>(null!);

    useEffect(() => {
        userService.getPhoto(article.author.emailAddress)
            .then(photo => setPhoto(photo))
            .catch(() => setPhoto(null!));
    }, [article.author.emailAddress]);

    const created = dayjs(article.published!).format("DD/MM/YYYY");

    return (
        <ListGroup.Item action key={article.id} as={Link} to={`/it/kb/${article.id}`} className="py-0">
            <Container fluid>
                <Row className="align-items-stretch">
                    <Col xs={12} lg={4} className={styles.cell}>
                        <CellContent>
                            <span className="d-block d-lg-none fw-bold">{t("it.kb.articles.table.headers.title")}</span>
                            <span className="d-block fw-bold mb-2 mb-lg-0">{article.title}</span>

                            <span className="d-block text-muted small">{article.articleNumber!}</span>
                        </CellContent>
                    </Col>

                    <Col xs={12} lg={2} className={styles.cell}>
                        <CellContent>
                            <span className="d-block d-lg-none fw-bold">{t("it.kb.articles.table.headers.category")}</span>
                            <span className="d-block">{article.category.name}</span>
                        </CellContent>
                    </Col>

                    <Col xs={12} lg={2} className={styles.cell}>
                        <CellContent>
                            <span className="d-block d-lg-none fw-bold">{t("it.kb.articles.table.headers.system")}</span>
                            <span className="d-block">{article.system?.name}</span>
                        </CellContent>
                    </Col>

                    <Col xs={12} lg={2} className={styles.cell}>
                        <CellContent>
                            <span className="d-block d-lg-none fw-bold">{t("it.kb.articles.table.headers.author")}</span>
                            <span className="d-flex align-items-center">
                                {
                                    photo &&
                                    <img src={`data:image/any;base64,${photo}`} alt={article.author.name} className={styles.avatar} />
                                }
                                <span>{article.author.name}</span>
                            </span>
                        </CellContent>
                    </Col>

                    <Col xs={12} lg={2} className={styles.cell}>
                        <CellContent>
                            <span className="d-block d-lg-none fw-bold">{t("it.kb.articles.table.headers.published")}</span>
                            <span className="d-block">{created}</span>
                        </CellContent>
                    </Col>
                </Row>
            </Container>
        </ListGroup.Item>
    );
}