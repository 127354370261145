import { Button } from "react-bootstrap";
import { Icon } from "../../components/icon/Icon";
import { useNavigate } from "react-router-dom";
import IBackButtonProps from "./IBackButtonProps";

export default function BackButton(props: IBackButtonProps) {
    const navigate = useNavigate();

    return (
        <Button type="button" variant="light" size="sm" className="me-3" onClick={() => navigate(props.path)}>
            <Icon iconName="ChevronLeft" />
        </Button>
    );
}