import networkService from "./networkService";

class HelpdeskService {

    /**
     * @description Gets the helpdesk a ticket belongs to
     * @param {number} ticketId The ID of the ticket to get the type for
     */
    async getTicketType(ticketId: number): Promise<string> {
        const result = await networkService.get<string>(`helpdesk/ticket/${ticketId}/type`);

        if (result.isSuccessful) {
            return result.data as string;
        }

        throw result.data;
    }

    /**
     * @description Gets the ID of a helpdesk ticket's current status
     * @param {number} ticketId The ID of the ticket to get the status ID for
     */
    async getTicketStatusId(ticketId: number): Promise<string> {
        const result = await networkService.get<string>(`helpdesk/ticket/${ticketId}/step`);

        if (result.isSuccessful) {
            return result.data as string;
        }

        throw result.data;
    }
}

const helpdeskService = new HelpdeskService();
export default helpdeskService;