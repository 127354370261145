import { Alert, Col, ListGroup, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import approvalService from "../../services/approvalService";
import { useEffect, useState } from "react";
import Status from "../../enums/Status";
import IApproval from "../../models/approvals/IApproval";
import Request from "./Request";
import RequestOutcome from "../../enums/RequestOutcome";
import { AwaitingReviewHeader } from "./headers/AwaitingReviewHeader";
import { ReviewedHeader } from "./headers/ReviewedHeader";

export default function Requests() {
    const { t } = useTranslation();

    const [status, setStatus] = useState<Status>(Status.LOADING);
    const [requests, setRequests] = useState<IApproval[]>([]);

    useEffect(() => {
        getRequests(setRequests)
            .then(() => setStatus(Status.LOAD_SUCCESS))
            .catch(() => setStatus(Status.LOAD_ERROR));
    }, []);

    if (status === Status.LOADING) {
        return <Alert variant="info">{t("general.loading")}</Alert>;
    } else if (status === Status.LOAD_ERROR) {
        return <Alert variant="danger">{t("approval.errors.loadRequests")}</Alert>;
    } else if (status === Status.LOAD_SUCCESS && requests.length === 0) {
        return <Alert variant="info">{t("approval.errors.noRequests")}</Alert>;
    }
    
    return (
        <Row>
            <Col>
                {
                    requests.filter(request => request.outcome === RequestOutcome.NOT_REVIEWED).length > 0 &&
                    <Row>
                        <Col>
                            <h2 className="h3">{t("approval.requests.title.awaitingReview")}</h2>
                            <ListGroup variant="flush" className="mb-3">
                                <AwaitingReviewHeader />
                                {
                                    requests
                                    .filter(request => request.outcome === RequestOutcome.NOT_REVIEWED)
                                    .map(request => <Request key={request.id} request={request} />)
                                }
                            </ListGroup>
                        </Col>
                    </Row>
                }
                {
                    requests.filter(request => request.outcome !== RequestOutcome.NOT_REVIEWED).length > 0 &&
                    <Row>
                        <Col>
                            <h2 className="h3">{t("approval.requests.title.previouslyReviewed")}</h2>
                            <ListGroup variant="flush" className="mb-3">
                                <ReviewedHeader />
                                {
                                    requests
                                        .filter(request => request.outcome !== RequestOutcome.NOT_REVIEWED)
                                        .map(request => <Request key={request.id} request={request} />)
                                }
                            </ListGroup>
                        </Col>
                    </Row>
                }
            </Col>
        </Row>
    );
}

async function getRequests(setRequests: (requests: IApproval[]) => void) {
    const result = await approvalService.getRequests();
    const sorted = result.sort((a, b) => new Date(b.ticket.created!).getTime() - new Date(a.ticket.created!).getTime());
    
    setRequests(sorted);
}