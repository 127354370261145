import { useEffect, useState } from "react";
import ITicket from "../../../models/helpdesk/it/ITicket";
import itHelpdeskService from "../../../services/itHelpdeskService";
import { useTranslation } from "react-i18next";
import Status from "../../../enums/Status";
import { Alert, Col, Container, ListGroup, Row, Spinner } from "react-bootstrap";
import Ticket from "./Ticket";
import { v4 as uuid } from "uuid";

const TicketsHeader = () => {
    const { t } = useTranslation();

    return (
        <ListGroup.Item key={uuid()}>
            <Container fluid className="d-none d-lg-block">
                <Row>
                    <Col xs={4} className="fw-bold">{t("it.my.table.headers.title")}</Col>
                    <Col xs={3} className="fw-bold">{t("it.my.table.headers.created")}</Col>
                    <Col xs={2} className="fw-bold">{t("it.my.table.headers.status")}</Col>
                    <Col xs={3} className="fw-bold">{t("it.my.table.headers.details")}</Col>
                </Row>
            </Container>
        </ListGroup.Item>
    );
}

export default function Tickets() {
    const { t } = useTranslation();

    const [tickets, setTickets] = useState<ITicket[]>([]);
    const [status, setStatus] = useState<Status>(Status.LOADING);

    useEffect(() => {
        getTickets(setTickets)
            .then(() => setStatus(Status.LOAD_SUCCESS))
            .catch(() => setStatus(Status.LOAD_ERROR));
    }, []);

    return (
        <Container fluid>
            <Row>
                <Col>
                    <h2>{t("it.my.title")}</h2>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col>
                    {
                        (
                            status === Status.LOADING &&
                            <div className="text-center">
                                <Spinner />
                                <span className="d-block sfw-bold">{t("it.my.loading")}</span>
                            </div>
                        ) ||
                        (
                            status === Status.LOAD_ERROR &&
                            <Alert variant="danger">
                                {t("it.my.errors.general")}
                            </Alert>
                        ) ||
                        (
                            tickets.length === 0 &&
                            <Alert variant="info">
                                {t("it.my.errors.noTickets")}
                            </Alert>
                        ) ||
                        <ListGroup variant="flush">
                            <TicketsHeader />
                            {
                                tickets.map(ticket => <Ticket key={uuid()} ticket={ticket} />)
                            }
                        </ListGroup>
                    }
                </Col>
            </Row>
        </Container>
    );
}

async function getTickets(setTickets: (tickets: ITicket[]) => void) {
    const tickets = await itHelpdeskService.getTickets();
    setTickets(tickets);
}