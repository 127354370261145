import { Row } from "react-bootstrap";
import ITopicTilesProps from "./ITopicTilesProps";
import { TopicTile } from "../tile/TopicTile";

export const TopicTiles = (props: ITopicTilesProps) => {
    const { topics, onClick } = props;
    
    return (
        <Row>
            {
                topics.map(topic => <TopicTile  key={topic.id}
                                                topic={topic}
                                                onClick={() => onClick(topic.id)} />
                             )
            }
        </Row>
    );
};