import { Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Tile from "../../components/tile/Tile";

interface ILinkTileProps {
    titleKey: string;
    descriptionKey: string;
    link: string;
    image: string;
}

const LinkTile = (props: ILinkTileProps) => {
    const { t } = useTranslation();

    return (
        <Col xs={12} md={6} lg={4} xl={3} className="mb-3">
            <Tile   title={t(props.titleKey)}
                    description={t(props.descriptionKey)}
                    link={props.link}
                    image={props.image}
                    />
        </Col>
    );
};

export default LinkTile;