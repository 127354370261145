import IArticle from "../models/kb/IArticle";
import networkService from "./networkService";

class KnowledgeBaseService {

    async getArticles() : Promise<IArticle[]> {
        const result = await networkService.get<IArticle[]>("kb/articles");

        if (result.isSuccessful) {
            return result.data as IArticle[];
        }

        throw result.data;
    }

    async getArticle(articleId: number) : Promise<IArticle> {
        const result = await networkService.get<IArticle>(`kb/article/${articleId}`);

        if (result.isSuccessful) {
            return result.data as IArticle;
        }

        throw result.data;
    }
}

const kbService = new KnowledgeBaseService();
export default kbService;