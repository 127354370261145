import { useNavigate } from "react-router-dom";
import ITileProps from "./ITileProps";
import styles from "./Tile.module.scss";
import { mergeStyles } from "@fluentui/merge-styles";

/**
 * @description Renders a tile with a link to another page.
 */
export default function Tile(props: ITileProps) {
    const navigate = useNavigate();

    let onClick: () => void;
    
    if (props.link && !props.onClick) {
        onClick = () => props.link!.startsWith("/")
            ? navigate(props.link!)
            : window.location.href = props.link!;
    } else if (props.onClick && !props.link) {
        onClick = props.onClick;
    } else {
        throw new Error("Either link or onClick must be provided, but not both.");
    }
    
    const backgroundImageUrl = `${process.env.PUBLIC_URL}${props.image}`;
    const backgroundImageClassName = mergeStyles({ backgroundImage: `url('${backgroundImageUrl}')` });

    return (
        <div className={`${styles.tile} ${backgroundImageClassName} h-100`} onClick={onClick}>
            <div className={styles.tileContent}>
                <h2 className={styles.title}>{props.title}</h2>
                <p className={styles.description}>{props.description}</p>
            </div>
        </div>
    );
}