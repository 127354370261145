import { mergeStyles } from "@fluentui/merge-styles";
import { Card, Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import styles from "./Authorise.module.scss";
import { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import Login from "../login/Login";

const AnonymousRoutes = () => (
    <Routes>
        <Route path='*' element={<Login />} />
    </Routes>
);

export default function Authorise() {
    const { t } = useTranslation();

    const [fade, setFade] = useState<boolean>(false);
    useEffect(() => setFade(true), []);

    const authoriseCss = mergeStyles(styles.authorise, fade && styles.fade);

    const backgroundImagePath = `${process.env.PUBLIC_URL}/img/macro_desktop.jpg`;
    const backgroundCss = mergeStyles({ backgroundImage: `url(${backgroundImagePath})` }, styles.authoriseMain);

    return (
        <main role="main" className={`${backgroundCss} w-100 min-vh-100`}>
            <div className={authoriseCss}>
                <Container>
                    <Row className="justify-content-center">
                        <Col xs={12} lg={8} xl={6}>
                            <Card>
                                <Card.Header>
                                    <h2>{t('application.name')}</h2>
                                </Card.Header>
                                <AnonymousRoutes />
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </main>
    );
}