import { Col, Container, ListGroup, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export const ReviewedHeader = () => {
    const { t } = useTranslation();

    return (
        <ListGroup.Item className="d-none d-lg-block">
            <Container fluid>
                <Row>
                    <Col xs={12} lg={4} className="fw-bold">
                        {t("approval.table.headers.title")}
                    </Col>
                    <Col xs={12} lg={2} className="fw-bold">
                        {t("approval.table.headers.created")}
                    </Col>
                    <Col xs={12} lg={1} className="fw-bold">
                        {t("approval.table.headers.outcome")}
                    </Col>
                    <Col xs={12} lg={3} className="fw-bold">
                        {t("approval.table.headers.requestedFor")}
                    </Col>
                    <Col lg={2} className="d-none d-lg-block fw-bold">
                        {t("approval.table.headers.costCode")}
                    </Col>
                </Row>
            </Container>
        </ListGroup.Item>
    );
};