import IAsset from "../models/assets/IAsset";
import ILAPSDetails from "../models/assets/ILAPSDetails";
import networkService from "./networkService";

class DeviceService {
    
    /**
     * @description Fetches all devices from the asset management system
     */
    async getDevices(justMine: boolean = false) : Promise<IAsset[]> {
        const path = justMine
            ? "devices"
            : "devices/all";

        const result = await networkService.get<IAsset[]>(path);

        if (!result.isSuccessful) {
            throw result.data;
        }

        return result.status === 200 
            ? result.data as IAsset[]
            : [];
    }

    /**
     * @description Fetches a specific device from the asset management system
     * @param {number} deviceId The ID of the device to be returned
     */
    async getDevice(deviceId: number) : Promise<IAsset> {
        const result = await networkService.get<IAsset>(`device/${deviceId}`);

        if (result.isSuccessful) {
            return result.data as IAsset;
        }

        throw result.data;
    }

    /**
     * @description Fetches the name of a laptop device from Intune
     * @param {number} deviceId The ID of the device asset to be queried
     */
    async getDeviceLaptopName(deviceId: number) : Promise<string> {
        const result = await networkService.get<any>(`device/${deviceId}/device-name`);

        if (result.isSuccessful) {
            return result.data as string;
        }

        throw result.data;
    }

    /**
     * @description Fetches the LAPS data for a device
     * @param {number} deviceId The ID of the device to query LAPS data for
     */
    async getLAPSDetails(deviceId: number) : Promise<ILAPSDetails[]> {
        const result = await networkService.get<any>(`device/${deviceId}/laps`);

        if (!result.isSuccessful) {
            throw result.data;
        }

        return result.status === 200 
            ? result.data as ILAPSDetails[]
            : [];
    }
}

const deviceService = new DeviceService();
export default deviceService;