import { Col } from "react-bootstrap";
import Tile from "../../../../../components/tile/Tile";
import ITopicTileProps from "./ITopicTileProps";

const baseUrl = process.env.REACT_APP_API_BASE_URL || "/api/";

const TileImageUrl = (id: string) => `${baseUrl}helpdesk/it/topic/${id}/image`;

export const TopicTile = (props: ITopicTileProps) => (
    <Col xs={12} md={6} lg={4} className="mb-3">
        <Tile   title={props.topic.name}
                description={props.topic.subtitle}
                onClick={props.onClick}
                image={TileImageUrl(props.topic.id)}
                />
    </Col>
);