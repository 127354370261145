import { useTranslation } from "react-i18next";
import ITicket from "../../../models/helpdesk/hr/ITicket";
import { Badge, Card, Col, Container, Nav, OverlayTrigger, Row, Tab, Tooltip } from "react-bootstrap";
import { Icon } from "../../../components/icon/Icon";
import BackButton from "../../../components/backButton/BackButton";
import TicketTab from "../../ticketTab/TicketTab";
import DetailsTab from "./tabs/details/DetailsTab";
import { MessageTab } from "../../common/tabs/comms/MessageTab";
import AttachmentsTab from "../../common/tabs/attachments/AttachmentsTab";

export default function TicketBody({ ticket }: { ticket: ITicket }) {
    const { t } = useTranslation();

    return (
        <Card.Body>
            <Card.Title className="d-flex align-items-center mb-3">
                <BackButton path="/hr" />
                <span>{ticket.ticketNumber}</span>
            </Card.Title>
            <Card.Text as="div">
                <Container fluid>
                    <Row>
                        <Col>
                            <h1 className="h2">{ticket.title}</h1>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="d-flex gap-2 mb-3 w-100 overflow-x-auto">
                                <OverlayTrigger placement="top" overlay={<Tooltip>{t("ticket.badges.status")}</Tooltip>}>
                                    <Badge bg="primary" className="fw-normal">
                                        <Icon iconName="Clock" endMargin={1} />
                                        <span className="mb-0">{ticket.status?.name}</span>
                                    </Badge>
                                </OverlayTrigger>
                                {
                                    ticket.assignee?.name &&
                                    <OverlayTrigger placement="top" overlay={<Tooltip>{t("ticket.badges.assignee")}</Tooltip>}>
                                        <Badge bg="info" className="fw-normal">
                                            <Icon iconName="Person" endMargin={1} />
                                            <span className="mb-0">{ticket.assignee!.name}</span>
                                        </Badge>
                                    </OverlayTrigger>
                                }
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Tab.Container defaultActiveKey="info">
                                <Row>
                                    <Col xs={12} lg={3} xl={2}>
                                        <Nav variant="pills" className="flex-lg-column">
                                            <TicketTab eventKey="info" icon="InfoCircleFill" label={t("it.ticketTabs.details")} />
                                            <TicketTab eventKey="comms" icon="ChatDots" label={t("comms.tabName")} />
                                            <TicketTab eventKey="attachments" icon="Paperclip" label={t("attachments.title")} />
                                        </Nav>
                                    </Col>
                                    <Col sm={9}>
                                        <Tab.Content>
                                            <Tab.Pane eventKey="info">
                                                <DetailsTab ticket={ticket} />
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="comms">
                                                <MessageTab ticket={ticket} />
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="attachments">
                                                <AttachmentsTab ticket={ticket} />
                                            </Tab.Pane>
                                        </Tab.Content>
                                    </Col>
                                </Row>
                            </Tab.Container>
                        </Col>
                    </Row>
                    
                </Container>
            </Card.Text>
        </Card.Body>
    );
};