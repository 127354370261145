import IAlertBodyProps from "./IAlertBodyProps";
import { Alert, Card } from "react-bootstrap";
import BackButton from "../../../../components/backButton/BackButton";
import { Link } from "react-router-dom";

const AlertBody = (props: IAlertBodyProps) => props.ticket
    ?   <Card.Body>
            <Card.Title className="d-flex align-items-center">
                <div className="d-flex align-items-center mb-3">
                    <BackButton path="/it" />
                    <Link to={`/it/ticket/${props.ticket.id}`} className="text-decoration-none">
                        <span>{props.ticket.ticketNumber}</span>
                    </Link>
                </div>
            </Card.Title>
            <Alert variant={props.variant} className="w-100 mb-0">
                {props.message}
            </Alert>
        </Card.Body>
    :   <Card.Body className="d-flex align-items-center">
            <BackButton path="/it" />
            <Alert variant={props.variant} className="w-100 mb-0">
                {props.message}
            </Alert>
        </Card.Body>;

export default AlertBody;