import { Button, Col, Form, ListGroup, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import ISelfHelpSectionProps from "./ISelfHelpSectionProps";

export default function SelfHelpSection(props: ISelfHelpSectionProps) {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [showContinue, setShowContinue] = useState(false);

    const onArticleClick = (articleId: number) => navigate(`/it/kb/${articleId}`);
    const onLinkClick = () => navigate("/it/kb");

    const onCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => setShowContinue(event.target.checked);

    return (
        <Row>
            <Col>
                <Row>
                    <Col>
                        <h2>{t("it.new.pre-submission.self-help.title")}</h2>
                        <p>{t("it.new.pre-submission.self-help.description")}</p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <ul>
                            <li>{t("it.new.pre-submission.selection.platform", { platform: props.platform.name })}</li>
                            <li>{t("it.new.pre-submission.selection.topic", { topic: props.topic.name })}</li>
                        </ul>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p>{t("it.new.pre-submission.self-help.articles")}</p>
                        <Row>
                            <Col>
                                <ListGroup>
                                    {
                                        props.topic.articles.map(article => (
                                            <ListGroup.Item action key={article.id} onClick={() => onArticleClick(article.id)}>
                                                <p className="mb-0 fw-bold">{article.title}</p>
                                                <p className="mb-0 text-muted">{article.articleNumber}</p>
                                            </ListGroup.Item>
                                        ))
                                    }
                                </ListGroup>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col>
                                <Button variant="link" onClick={onLinkClick}>
                                    {t("it.new.pre-submission.self-help.link")}
                                </Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Check type="checkbox" label={t("it.new.pre-submission.self-help.checkbox")} onChange={onCheckboxChange} />
                            </Col>
                        </Row>
                        {
                            showContinue &&
                            <Row className="mt-3">
                                <Col>
                                    <Button onClick={props.continue}>{t("general.continue")}</Button>
                                </Col>
                            </Row>
                        }
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}