import { Col, Container, Row } from "react-bootstrap";
import Tile from "../../components/tile/Tile";
import { useTranslation } from "react-i18next";
import Tickets from "./tickets/Tickets";

export function Helpdesk() {
    const { t } = useTranslation();
    
    return (
        <Container fluid>
            <Row className="mb-3">
                <Col>
                    <h1>{t("hr.title")}</h1>
                    <p className="lead">{t("hr.description")}</p>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col xs={12} md={6} lg={3} className="mb-3">
                    <Tile   title={t("hr.new.title")}
                            description={t("hr.new.description")}
                            link="/hr/ticket"
                            image="/img/tiles/create-case.jpg"
                            />
                </Col>
                {
                    process.env.REACT_APP_FEATURE_FLAG_PEOPLE_POLICIES_SUPPORT === "true" &&
                    <Col xs={12} md={6} lg={3} className="mb-3">
                        <Tile   title={t("hr.policies.title")}
                                description={t("hr.policies.description")}
                                link="https://macrogroupltd.sharepoint.com/sites/HR/Shared%20Documents/Forms/AllItems.aspx"
                                image="/img/tiles/policies_tile.jpg"
                                />
                    </Col>
                }
            </Row>
            <Row className="mt-1">
                <Col>
                    <Tickets />
                </Col>
            </Row>
        </Container>
    );
}