import { Container, Nav, Navbar } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import styles from './Navbar.module.scss';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Account from './account/Account';

interface INavLinkProps {
    href: string;
    text: string;
}

const Logo = () => {
    const { t } = useTranslation();

    const logoUrl = `${process.env.PUBLIC_URL}/img/logos/macro/macro_blue.svg`;
    return (<img src={logoUrl} alt={t("dolphin.company.name")} className={styles.logo} />);
};

const NavLink = (props: INavLinkProps) => {
    let linkClassName = styles.link;

    const rootPath = window.location.pathname !== "/"
        ? window.location.pathname.split("/")[1].toLowerCase()
        : "/";
        
    const isActive = props.href.toLowerCase().startsWith(`/${rootPath}`) && rootPath !== "/";

    return (
        <Nav.Link   as={Link}
                    to={props.href}
                    className={linkClassName}
                    active={isActive}>
                        {props.text}
        </Nav.Link>
    );
}

export default function Sidebar() {
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <Navbar expand="lg" className={`${styles.navbar} navbar-dark`}>
            <Container fluid>
                <Navbar.Brand onClick={() => navigate("/")}>
                    <Logo />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="navbar-collapse" />
                <Navbar.Collapse id="navbar-collapse">
                    <Nav className="me-auto">
                        {
                            process.env.REACT_APP_FEATURE_FLAG_PEOPLE_SUPPORT === "true" &&
                            <NavLink href="/hr" text={t("hr.title")} />
                        }
                        {
                            process.env.REACT_APP_FEATURE_FLAG_IT_SUPPORT === "true" &&
                            <NavLink href="/it" text={t("it.title")} />
                        }
                    </Nav>
                    <Account />
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}