import { useTranslation } from "react-i18next";
import IApproval from "../../models/approvals/IApproval";
import { useEffect, useState } from "react";
import userService from "../../services/userService";
import dayjs from "dayjs";
import { Accordion, Card, Col, Container, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import BackButton from "../../components/backButton/BackButton";
import { Icon } from "../../components/icon/Icon";
import styles from "./RequestBody.module.scss";
import { Link } from "react-router-dom";
import RequestOutcome from "../../enums/RequestOutcome";
import IReviewer from "../../models/approvals/IReviewer";
import ApprovalForm from "./approvalForm/ApprovalForm";

interface IOutcomeProps {
    outcome: RequestOutcome;
    reason?: string;
    reviewer: IReviewer;
}

const Outcome = (props: IOutcomeProps) => {
    const { t } = useTranslation();
    const { outcome, reason, reviewer } = props;

    const [reviewedByPhoto, setReviewedByPhoto] = useState<string>(null!);

    useEffect(() => {
        getPhoto(reviewer.email, setReviewedByPhoto);
    }, [reviewer.email]);

    const decisionColour = outcome !== RequestOutcome.NOT_REVIEWED
        ? outcome === RequestOutcome.ACCEPTED 
            ? "success"
            : "danger"
        : null!;

    return (
        <div className={`d-block rounded shadow p-3 my-3 text-light bg-${decisionColour}`}>
            <h2 className="h3">
                {t(`approval.outcomes.${outcome}`)}
            </h2>
            <DisplayPhoto photo={reviewedByPhoto} name={reviewer.name} text={t("approval.details.requestInfo.reviewedBy", { name: reviewer.name })} />
            {
                reason && <div className="d-block" dangerouslySetInnerHTML={{ __html: reason }} />
            }
        </div>
    );
};

export default function RequestBody({ request }: { request: IApproval }) {
    const { t } = useTranslation();

    const [requesterPhoto, setRequesterPhoto] = useState<string>(null!);
    const [requestedByPhoto, setRequestedByPhoto] = useState<string>(null!);

    useEffect(() => {
        getPhoto(request.requester.email, setRequesterPhoto);
        getPhoto(request.requestedBy.email, setRequestedByPhoto);
    }, [request.requester.email, request.requestedBy.email]);

    const created = dayjs(request.ticket.created).format("DD/MM/YYYY");

    return (
        <>
            <Card.Body>
                <Card.Title className="d-flex align-items-center mb-3">
                    <BackButton path="/requests" />
                    <span>{request.requestNumber}</span>
                </Card.Title>
                <Card.Text as="div">
                    <Container fluid>
                        <Row className="mb-3">
                            <Col>
                                <Row>
                                    <Col>
                                        <h1 className="h2">
                                            {request.ticket.title}
                                        </h1>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <div className="d-flex align-items-center fs-6">
                                            <Icon iconName="Calendar" endMargin={2} />
                                            <span>{created}</span>
                                        </div>
                                    </Col>
                                </Row>
                                {
                                    request.outcome !== RequestOutcome.NOT_REVIEWED &&
                                    <Outcome outcome={request.outcome} reason={request.reason} reviewer={request.reviewer} />
                                }
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col>
                                <Accordion defaultActiveKey="0">
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>{t("approval.details.headers.requestInfo")}</Accordion.Header>
                                        <Accordion.Body>
                                            <DisplayPhoto photo={requesterPhoto} name={request.requester.name} text={t("approval.details.requestInfo.requestedFor", { name: request.requester.name })} />

                                            {
                                                request.costCode &&
                                                <div className="d-inline-block">
                                                    <OverlayTrigger placement="top" overlay={<Tooltip>{t("approval.details.requestInfo.costCode")}</Tooltip>}>
                                                        <div className="d-flex align-items-center fs-5 mt-4 mb-1">
                                                            <Icon iconName="CurrencyPound" endMargin={2} />
                                                            <span>{request.costCode}</span>
                                                        </div>
                                                    </OverlayTrigger>
                                                </div>
                                            }

                                            {
                                                request.isCostCodeRequired &&
                                                request.outcome === RequestOutcome.NOT_REVIEWED &&
                                                <div className="d-flex align-items-center fs-5 mt-4 mb-1 text-primary">
                                                    <Icon iconName="InfoCircleFill" endMargin={2} />
                                                    <span className="fst-italic">{t("approval.details.requestInfo.costCodeRequired")}</span>
                                                </div>
                                            }

                                            <div className="d-flex align-items-center fs-5 mt-4 mb-1">
                                                <Icon iconName="StickyFill" endMargin={2} />
                                                <span>{t("approval.details.requestInfo.note")}</span>
                                            </div>
                                            <p>{request.note}</p>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header>{t("approval.details.headers.caseInfo")}</Accordion.Header>
                                        <Accordion.Body>
                                            <p className="lead mb-0" dangerouslySetInnerHTML={{ __html: t("approval.details.caseInfo.caseNumber", { number: request.ticket.ticketNumber }) }} />
                                            <p className="lead mb-2" dangerouslySetInnerHTML={{ __html: t("approval.details.caseInfo.title", { title: request.ticket.title }) }} />
                                            <p className="lead mb-0" dangerouslySetInnerHTML={{ __html: t("approval.details.caseInfo.description") }} />
                                            <div dangerouslySetInnerHTML={{ __html: request.ticket.description }} />
                                            <Link to={`/it/ticket/${request.ticket.id}`} className="d-block mt-3">
                                                {t("approval.details.caseInfo.link")}
                                            </Link>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </Col>
                        </Row>
                        {
                            request.outcome === RequestOutcome.NOT_REVIEWED && <ApprovalForm requestId={request.id} isCostCodeRequired={request.isCostCodeRequired} />
                        }
                    </Container>
                </Card.Text>
            </Card.Body>
            <Card.Footer>
                <DisplayPhoto photo={requestedByPhoto} name={request.requestedBy.name} text={t("approval.details.requestInfo.requestedBy", { name: request.requestedBy.name })} />
            </Card.Footer>
        </>
    );
};

const DisplayPhoto = ({ photo, name, text } : { photo: string, name: string, text: string }) => (
    <div className="d-flex align-items-center fs-5 mb-1">
        {
            (photo && <img src={`data:image/any;base64,${photo}`} alt={name} className={styles.avatar} />) ||
            <Icon iconName="Person" endMargin={2} />
        }
        <span>{text}</span>
    </div>
);

async function getPhoto(username: string, setPhoto: (photo: string) => void) {
    try {
        const photo = await userService.getPhoto(username);
        setPhoto(photo || null!);
    } catch (_) {
        setPhoto(null!);
    }
}