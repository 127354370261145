import { Card, Col, Container, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import Status from "../../../enums/Status";
import { Icon } from "../../../components/icon/Icon";
import { useEffect, useState } from "react";
import ITicket from "../../../models/helpdesk/it/ITicket";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import itHelpdeskService from "../../../services/itHelpdeskService";
import FeedbackBody from "./FeedbackBody";
import { NIL as empty_guid } from "uuid";
import helpdeskService from "../../../services/helpdeskService";
import { FeedbackType } from "../../../enums/FeedbackType";
import AlertBody from "./alertBody/AlertBody";

export function Feedback() {
    const { t } = useTranslation();
    const { ticketId } = useParams();

    const [status, setStatus] = useState<Status>(Status.LOADING);
    const [ticket, setTicket] = useState<ITicket>(null!);
    const [stepId, setStepId] = useState<string>(empty_guid);

    const id = Number(ticketId);

    useEffect(() => {
        fetchInformation(id, setTicket, setStepId, setStatus);
    }, [id]);

    const createdFormatted = ticket?.created
        ? dayjs(ticket!.created).format("DD/MM/YYYY HH:mm")
        : null;

    const hasFeedback = ticket?.feedback && ticket.feedback.type !== FeedbackType.NEUTRAL;
    
    return (
        <Container className="py-3">
            <Row className="justify-content-center">
                <Col>
                    <Card className="shadow">
                        {
                            (status === Status.LOADING && <AlertBody variant="info" message={t("ticket.loading")} />) ||
                            (status === Status.LOAD_ERROR && <AlertBody variant="danger" message={t("ticket.errors.general")} />) ||
                            (status === Status.LOAD_SUCCESS && !ticket.isEligibleForFeedback && <AlertBody variant="info" message={t("feedback.errors.ineligible")} ticket={ticket} />) ||
                            (status === Status.LOAD_SUCCESS && hasFeedback && <AlertBody variant="info" message={t("feedback.errors.alreadySubmitted")} ticket={ticket} />) ||
                            (status === Status.LOAD_SUCCESS && !hasFeedback && stepId === process.env.REACT_APP_WEBCON_IT_HD_COMPLETED_STEP_ID && <AlertBody variant="info" message={t("feedback.errors.alreadyClosed")} ticket={ticket} />) ||
                            (status === Status.LOAD_SUCCESS && !hasFeedback && stepId === process.env.REACT_APP_WEBCON_IT_HD_ARCHIVED_STEP_ID && <AlertBody variant="info" message={t("feedback.errors.archived")} ticket={ticket} />) ||
                            (status === Status.LOAD_SUCCESS && !hasFeedback && stepId !== process.env.REACT_APP_WEBCON_IT_HD_COMPLETED_PENDING_FEEDDBACK_STEP_ID && <AlertBody variant="info" message={t("feedback.errors.notInFeedbackStep")} ticket={ticket} />) ||
                            (status === Status.LOAD_SUCCESS && !hasFeedback && <FeedbackBody ticket={ticket} />)
                        }
                        {
                            status === Status.LOAD_SUCCESS && createdFormatted &&
                            <Card.Footer>
                                <OverlayTrigger placement="top" overlay={<Tooltip>{t("general.created")}</Tooltip>}>
                                    <span>
                                        <Icon iconName="Calendar" endMargin={1} />
                                        <span className="mb-0">{createdFormatted}</span>
                                    </span>
                                </OverlayTrigger>
                            </Card.Footer>
                        }
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}

async function  fetchInformation(
    ticketId: number,
    setTicket: (ticket: ITicket) => void,
    setStepId: (stepId: string) => void,
    setStatus: (status: Status) => void
) {
    try {
        const ticket = await itHelpdeskService.getTicket(ticketId);
        setTicket(ticket);

        const stepId = await helpdeskService.getTicketStatusId(ticketId);
        setStepId(stepId);

        setStatus(Status.LOAD_SUCCESS);
    } catch (error) {
        setStatus(Status.LOAD_ERROR);
    }
}