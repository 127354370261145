import { Alert, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import FloatingTextField from "../../../../components/formFields/floatingText/FloatingTextField";
import FormFieldValidation from "../../../../enums/FormFieldValidation";
import RichTextField from "../../../../components/formFields/richText/RichTextField";
import Dropzone from "../../../../components/formFields/dropzone/Dropzone";
import { v4 as uuid } from "uuid";
import IDetailsSectionProps from "./IDetailsSectionProps";
import { Question } from "./question-field/QuestionField";

export default function DetailsSection(props: IDetailsSectionProps) {
    const { t } = useTranslation();

    const handleQuestionAnswerChange = (questionId: number, value: string) => props.onQuestionAnswerChange(questionId, value);

    const handleFileDrop = (acceptedFiles: File[]) => {
        const newFiles = acceptedFiles.map(file => ({ id: uuid(), file }));
        const newFileList = [...props.dropzoneFiles, ...newFiles];
        props.setDropzoneFiles(newFileList);
    };

    const handleFileRemove = (id: string) => {
        const newFiles = props.dropzoneFiles.filter(file => file.id !== id);
        props.setDropzoneFiles(newFiles);
    };

    const hasValidationErrors = Object.values(props.validation).some(v => v === FormFieldValidation.INVALID);

    return (
        <Row>
            <Col>
                <Row>
                    <Col>
                        <h2>{t("it.new.pre-submission.details.title")}</h2>
                        <p>{t("it.new.pre-submission.details.description")}</p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FloatingTextField
                            label={t("ticket.form.fields.title.label")}
                            type="text"
                            placeholder={t("ticket.form.fields.title.placeholder")}
                            value={props.ticket.title}
                            disabled={props.isReadOnly}
                            onChange={value => props.onInputChange("title", value)}
                            validation={props.validation.title}
                            validationMessage={t("ticket.form.fields.title.validation")}
                            />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <ul>
                            <li>{t("it.new.pre-submission.selection.platform", { platform: props.ticket.platform!.name })}</li>
                            <li>{t("it.new.pre-submission.selection.topic", { topic: props.ticket.topic!.name })}</li>
                        </ul>
                    </Col>
                </Row>
                {
                    props.ticket.questions.length > 0 &&
                    <Row>
                        <Col>
                            <Row>
                                <Col>
                                    <h4>{t("it.new.pre-submission.questions.title")}</h4>
                                    <p>{t("it.new.pre-submission.questions.description")}</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    {
                                        props.ticket.questions.map(question => <Question
                                                                            key={question.id}
                                                                            question={question}
                                                                            isReadOnly={props.isReadOnly}
                                                                            onChange={handleQuestionAnswerChange}
                                                                            formHasValidationErrors={hasValidationErrors}
                                                                            />
                                                           )
                                    }
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                }
                <Row>
                    <Col>
                        <h4>{t("ticket.form.fields.description.label")}</h4>
                        <RichTextField
                            value={props.ticket.description}
                            placeholder={t("ticket.form.fields.description.placeholder")}
                            disabled={props.isReadOnly}
                            onChange={value => props.onInputChange("description", value)}
                            validation={props.validation.description}
                            validationMessage={t("ticket.form.fields.description.validation")}
                            />
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <Dropzone
                            items={props.dropzoneFiles}
                            disabled={props.isReadOnly}
                            onDrop={handleFileDrop}
                            onRemove={handleFileRemove}
                            />
                    </Col>
                </Row>

                <Row>
                    <Col>
                        {
                            hasValidationErrors &&
                            <Alert variant="danger">
                                {t("ticket.form.errors.validation")}
                            </Alert>
                        }
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}