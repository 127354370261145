import { useTranslation } from "react-i18next";
import LargeFloatingTextField from "../../../../../components/formFields/largeFloatingText/LargeFloatingTextField";
import FormFieldValidation from "../../../../../enums/FormFieldValidation";
import IQuestionFieldProps from "./IQuestionFieldProps";

export const Question = (props: IQuestionFieldProps) => {
    const { t } = useTranslation();

    const validation = 
        props.formHasValidationErrors
            ? props.question.answer && props.question.answer.trim().length > 0
                ? FormFieldValidation.VALID
                : FormFieldValidation.INVALID
            : FormFieldValidation.NOT_VALIDATED;

    return (
        <LargeFloatingTextField
            key={props.question.id}
            label={props.question.text}
            type="text"
            placeholder={props.question.text}
            value={props.question.answer ?? ""}
            disabled={props.isReadOnly}
            onChange={value => props.onChange(props.question.id, value)}
            validation={validation}
            validationMessage={t("ticket.form.fields.question.validation")}
            height={100}
            />
    );
}