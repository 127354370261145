import { Nav } from "react-bootstrap";
import ITicketTabProps from "./ITicketTabProps";
import { Icon } from "../../components/icon/Icon";
import i18n from "i18next";

const TicketTab = (props: ITicketTabProps) => (
    <Nav.Item>
        <Nav.Link eventKey={props.eventKey}>
            <Icon iconName={props.icon} />
            <span className="d-none d-lg-inline ms-2">{i18n.t(props.label)}</span>
        </Nav.Link>
    </Nav.Item>
);

export default TicketTab;