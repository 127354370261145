import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Laptops from "./laptops/Laptops";

export default function LAPS() {
    const { t } = useTranslation();

    return (
        <Container fluid>
            <Row className="mb-3">
                <Col>
                    <h1>{t("laps.title.long")}</h1>
                    <p className="lead">{t("laps.description")}</p>
                </Col>
            </Row>
            <Row className="mt-1">
                <Col>
                    <Laptops />
                </Col>
            </Row>
        </Container>
    );
}