import { Row } from "react-bootstrap";
import IPlatformTilesProps from "./IPlatformTilesProps";
import { PlatformTile } from "../tile/PlatformTile";

export const PlatformTiles = (props: IPlatformTilesProps) => {
    const { platforms, onClick } = props;
    
    return (
        <Row>
            {
                platforms.map(platform => <PlatformTile key={platform.id}
                                                        platform={platform}
                                                        onClick={() => onClick(platform)} />
                             )
            }
        </Row>
    );
};